import React,{Fragment} from 'react';
import NavigationBar from '../components/NavigationBar';
import Figures from '../components/Figures';
import Offer from '../components/Offer';
import Comments from '../components/Comments';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <Fragment>
        <NavigationBar/>
        <Figures/>
        <Offer/>
        <Comments/>
        <Footer/>
    </Fragment>
  )
}

export default Home