import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const RussianBeginner = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question: 'Как вас зовут? … зовут Иван',
            answers: ['я', 'меня', 'у меня', 'мне'],
            key: 1,
            type: 0,
        },
        {
            question: 'Сколько тебе лет? … 20 лет',
            answers: ['мой', 'меня', 'я', 'мне'],
            key: 3,
            type: 0,
        },
        {
            question: 'Я приехал в Россию …',
            answers: ['в Англию', 'из Англии', 'с Англии', 'от Англии'],
            key: 1,
            type: 0,
        },
        {
            question: 'Мои родители живут …',
            answers: ['из Москвы', 'в Москве', 'в Москву', 'Москва'],
            key: 1,
            type: 0,
        },
        {
            question: 'Утром я иду …',
            answers: [
                'о библиотеке',
                'в библиотеку',
                'библиотека',
                'в библиотеке',
            ],
            key: 1,
            type: 0,
        },
        {
            question: 'На нашей улице нет …',
            answers: ['в магазине', 'магазина', 'о магазине', 'магазин'],
            key: 1,
            type: 0,
        },
        {
            question: 'У меня есть…',
            answers: [
                'о старшей сестре',
                'старшей сестре',
                'старшую сестру',
                'старшая сестра',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'В комнате Антона много …',
            answers: ['картин', 'картина', 'картину', 'картины'],
            key: 0,
            type: 0,
        },
        {
            question: 'Я часто думаю …',
            answers: ['друзьям', 'друзей', 'о друзьях', 'друзья'],
            key: 2,
            type: 0,
        },
        {
            question: 'Скажите, пожалуйста, где … аптека?',
            answers: ['есть', 'находятся', 'находится', '-'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Макс говорит на пяти иностранных языках. Он даже знает …',
            answers: ['по-китайски', 'китайский', 'Китай'],
            key: 1,
            type: 0,
        },
        {
            question: 'В выходные мы с друзьями … на море.',
            answers: ['ездили', 'ехали', 'ездила', 'ездил'],
            key: 0,
            type: 0,
        },
        {
            question: 'Квартира Антуана и Елены находится …',
            answers: [
                'на третий этаж',
                'на третьем этаже',
                'третий этаж',
                'третьему этажу',
            ],
            key: 1,
            type: 0,
        },
        {
            question: 'Уже поздно и мне пора ехать …',
            answers: ['дома', 'в дом', 'домой', 'до дому'],
            key: 2,
            type: 0,
        },
        {
            question: 'Маленьк…… девочка',
            answers: ['-ий', '-ая', '-ое'],
            key: 1,
            type: 0,
        },
        {
            question: 'Син…… кресло',
            answers: ['-ий', '-яя', '-ее'],
            key: 2,
            type: 0,
        },
        {
            question: 'Трудн…… задание',
            answers: ['-ый', '-ая', '-ое'],
            key: 2,
            type: 0,
        },
        {
            question: 'Яблоко вкусн…',
            answers: ['-ый', '-ая', '-ое'],
            key: 2,
            type: 0,
        },
        {
            question: 'Богат…… человек',
            answers: ['-ый', '-ая', '-ое'],
            key: 0,
            type: 0,
        },
        {
            question: 'Тёмн…… ночь',
            answers: ['-ый', '-ая', '-ое'],
            key: 1,
            type: 0,
        },
        {
            question: '– Как … зовут? \n – Меня зовут Марина.',
            answers: ['его', 'её', 'вас', 'нас'],
            key: 2,
            type: 0,
        },
        {
            question: 'Я живу … Лондоне.',
            answers: ['на', 'в', 'из', 'с'],
            key: 1,
            type: 0,
        },
        {
            question: 'Мне 27 …',
            answers: ['года', 'год', 'лет'],
            key: 2,
            type: 0,
        },
        {
            question: 'Это … брат и сестра.',
            answers: ['моя', 'мои', 'мой', 'нас'],
            key: 1,
            type: 0,
        },
    ]
    const types = ['']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);

    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            if (score <= 35) {
                alert('Your level is beginner');
                window.location.href = '/quiz-selection';
            } else if (score > 35) {
                alert('Your level es Elementary');
                window.location.href = '/quiz-selection';
            }
        }


    }, [answered])
    return (
        <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
    )
}

export default RussianBeginner