import { getDocs, collection, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import Cookies from 'js-cookie';
import Calendar from '../Calendar_';
import { WHITE } from '../Colors';

const Teacher = () => {
	const [activeClasses, setActiveClasses] = useState([]);

	const getClasses = async () => {
		const userClasses = [];
		const userDayClasses = [];

		const q = query(
			collection(db, 'courses'),
			where('teacher.name', '==', Cookies.get('name')),
			where('status', '==', 'started')
		); //OR
		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((doc) => {
			userClasses.push(doc.data());
		});
		userClasses.forEach((_class) => {
			for (
				let d = new Date();
				d <= new Date(_class.dateEnd);
				d.setDate(d.getDate() + 1)
			) {
				const auxDate = d.getDate() % 7;
				if (_class.schedule[auxDate].class) {
					const StartHourMinutes =
						_class.schedule[auxDate].starts.split(':');
					const EndHourMinutes =
						_class.schedule[auxDate].ends.split(':');
					d.setHours(StartHourMinutes[0], StartHourMinutes[1]);
					const startDate = d.toISOString();
					d.setHours(EndHourMinutes[0], EndHourMinutes[1]);
					const endDate = d.toISOString();
					// TODO assign unique color to each class
					userDayClasses.push({
						id: startDate,
						color: '#fd3153',
						from: startDate,
						to: endDate,
						title: _class.language,
					});
				}
			}
		});
		setActiveClasses(userDayClasses);
	};

	useEffect(() => {
		getClasses();
	}, []);

	return (
		<div className='schedule-container bg-app'>
			<div className='user-schedule'>
				<div
					style={{
						display: 'flex',
						backgroundColor: WHITE,
						borderRadius: '0px 0px 15px 15px',
						boxShadow: '3px 3px 5px rgba(100,100,100,0.3)',
						padding: 10,
						width: '80vw',
					}}
				>
					<Calendar events={activeClasses} />
				</div>
			</div>
		</div>
	);
};

export default Teacher