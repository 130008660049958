import React from 'react'
import { NavLink } from 'react-router-dom'

const SpanishLevels = () => {
  return (
    <div className='bg-app std-height mt-5 pt-5'>
        <h2 className='text-center'>Levels</h2>
        <div className='levels-container'>
        <NavLink to='/spanish-beginner' className='language-button'>Beginner</NavLink>
        <NavLink to='/spanish-intermediate' className='language-button'>Intermediate</NavLink>
        <NavLink to='/spanish-upperintermediate' className='language-button'>Upper intermediate</NavLink>
        <NavLink to='/spanish-advance' className='language-button'>Advanced</NavLink>
        </div>
    </div>
  )
}

export default SpanishLevels