import React, { Fragment } from 'react';
import TeacherNavBar from '../../components/teacher/TeacherNavBar';
import Teacher from '../../components/teacher/Teacher';

const TeacherPage = () => {
  return (
    <Fragment>
      <TeacherNavBar/>
      <Teacher/>
    </Fragment>)
}

export default TeacherPage