import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrashAlt, FaSave, FaEraser } from "react-icons/fa";
import { db } from '../../firebase';
import { collection, deleteDoc, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";


const AdminTypes = () => {
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);
    const getTypes = async () => {
        const querySnapshot = await getDocs(collection(db, "types"));
        setTypes([]);
        querySnapshot.forEach((doc) => {
            setTypes(last => [...last, doc]);
        });
    }
    useEffect(() => {
        getTypes()
    }, [db]);

    const action = async () => {
        const id = document.getElementById('id');
        const typeIpt = document.getElementById('type');
        if (id.value === "") {
            //add
            await addDoc(collection(db, "types"), {
                type: typeIpt.value
            }).then(() => {
                clear();
                getTypes();
            });
        } else {
            //update
            const typeRef = doc(db, "types", id.value);
            await updateDoc(typeRef, {
                type: typeIpt.value
            }).then(() => {
                clear();
                getTypes();
            });
        }
    }

    const edit = (key, type) => {
        document.getElementById('id').value = key;
        document.getElementById('type').value = type;
    }
    const clear = () => {
        document.getElementById('type-form').reset();
    }
    const erase = async (key) => {
        await deleteDoc(doc(db,"types", key)).then(() => {
            getTypes();
        })
    }

    return (
        <div className='admin-types-container'>
            <div className='container-md'>
                <div className='container-md'>
                    <div className='d-flex justify-content-center my-3'>
                        <h2>{t('general.types')}</h2>
                    </div>
                    <form id='type-form' onSubmit={(e)=>e.preventDefault()}>
                        <div className='row my-2 mx-2'>
                            <label htmlFor='id' className='col-md-4'>Id:</label>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' name='id' id='id' />
                            </div>
                        </div>
                        <div className='row my-2 mx-2'>
                            <label htmlFor='id' className='col-md-4'>{t('general.type')}:</label>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' name='type' id='type' />
                            </div>
                        </div>
                        <div className='my-2 mx-2 d-flex justify-content-end'>
                            <button className='btn btn-secondary my-2 mx-1' onClick={clear}>{t('general.clear')} <FaEraser /> </button>
                            <button className='btn btn-primary my-2 mx-1' onClick={action}>{t('general.save')} <FaSave /> </button>
                        </div>
                    </form>
                </div>
                <div className='table-responsive'>
                    <table className='table table-md table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'>{t('general.type')}</th>
                                <th scope='col'>{t('general.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                types.map(type=>
                                    <tr key={type.id}>
                                        <td>{type.data().type}</td>
                                        <td>
                                            <button className='btn btn-warning mx-1 my-1' onClick={()=>edit(type.id,type.data().type)}><FaEdit/></button>
                                            <button className='btn btn-danger mx-1 my-1' onClick={()=>erase(type.id)}><FaTrashAlt/></button> 
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AdminTypes