import {
	getDocs,
	collection,
	query,
	where,
	doc,
	getDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import Cookies from 'js-cookie';
import Calendar from '../Calendar_';
import { WHITE } from '../Colors';
import TeacherInfo from '../teacher/TeacherInfo';

const Student = () => {
	const [activeClasses, setActiveClasses] = useState([{}]);
	const [teachers, setTeachers] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [activeTeacher, setActiveTeacher] = useState({
		name: '',
	});
	// TODO Change this query to search for where('students', 'array-contain', user-object)
	const getClasses = async () => {
		const q = query(
			collection(db, 'courses'),
			where('status', '==', 'ready')
		); //OR
		const querySnapshot = await getDocs(q);
		const userClasses = [];
		const userDayClasses = [];
		const userTeachers = [];
		const userTeachersInfo = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			const isInStudent = data.students.some(
				(student) => student.email === Cookies.get('email')
			);
			if (isInStudent) {
				userClasses.push(data);
			}
		});
		userClasses.forEach((_class) => {
			for (
				let d = new Date();
				d <= new Date(_class.dateEnd);
				d.setDate(d.getDate() + 1)
			) {
				const auxDate = d.getDate() % 7;
				if (_class.schedule[auxDate].class) {
					const StartHourMinutes =
						_class.schedule[auxDate].starts.split(':');
					const EndHourMinutes =
						_class.schedule[auxDate].ends.split(':');
					d.setHours(StartHourMinutes[0], StartHourMinutes[1]);
					const startDate = d.toISOString();
					d.setHours(EndHourMinutes[0], EndHourMinutes[1]);
					const endDate = d.toISOString();
					// TODO assign unique color to each class
					userDayClasses.push({
						id: startDate,
						color: '#fd3153',
						from: startDate,
						to: endDate,
						title: _class.language,
					});
				}
			}
			userTeachers.push(_class.teacher.id);
		});
		for (const teacherID of userTeachers) {
			const docRef = doc(db, 'users', teacherID);
			const data = await getDoc(docRef);
			userTeachersInfo.push(data.data());
		}
		setTeachers(userTeachersInfo);
		setActiveClasses(userDayClasses);
		setActiveTeacher(userTeachersInfo[0]);
	};

	useEffect(() => {
		if (Cookies.get('pending') !== undefined) {
			window.location.href = Cookies.get('pending');
		}
		getClasses();
	}, []);

	return (
		<div className='schedule-container bg-app'>
			<div className='user-schedule'>
				<div className='user-schedule-body'>
					<div
						style={{
							gridArea: 'calendar',
							backgroundColor: WHITE,
							borderRadius: '0px 0px 15px 15px',
							boxShadow: '3px 3px 5px rgba(100,100,100,0.3)',
							padding: 10,
							overflowY: 'scroll',
						}}
					>
						<Calendar events={activeClasses} />
					</div>
					<div
						style={{
							gridArea: 'professor-info',
						}}
					>
						{teachers.map((info, i) => (
							<TeacherInfo
								key={info.name}
								info={info}
								className={activeClasses[i].title}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Student;
