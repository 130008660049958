import React, {  useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const SpanishAdvanced = () => {
  const {t} = useTranslation();

  const questions = [
    {
      question:
        'A mí me pareció estupendo que ___________________ ese traje azul para la fiesta.',
      answers: [
        'trajeras puesto',
        'hubieras puesto',
        'hayas puesto',
      ],
      key: 2,
      type: 0,
      lessGood: 0,
    },
    {
      question:
        'Dijo que empezará a trabajar _____________ termine sus estudios universitarios.',
      answers: ['una vez', 'ya que', 'al fin'],
      key: 0,
      type: 0,
      lessGood: 1,
    },
    {
      question:
        'Ella vivió en Alemania por 8 años, de ahí que ______________ muy bien alemán.',
      answers: ['supo', 'sepa', 'sabe'],
      key: 1,
      type: 0,
      lessGood: 2,
    },
    {
      question:
        'La escuela está _______ 15 minutos de mi departamento.',
      answers: ['en', 'para', 'a'],
      key: 2,
      type: 0,
    },
    {
      question: 'Ni creas que todo __________ está arreglado.',
      answers: ['ya', 'todavía', 'aún'],
      key: 0,
      type: 0,
    },
    {
      question:
        '_____________________ dieta desde hace 3 meses y he bajado ya 7 kilos.',
      answers: [
        'sigo estando',
        'llevo haciendo',
        'he estado haciendo',
      ],
      key: 2,
      type: 0,
      lessGood: 1,
    },
    {
      question:
        'No está cansado, ____________ tuvo vacaciones esta semana.',
      answers: ['puesto que', 'sin embargo', 'no obstante'],
      key: 0,
      type: 0,
    },
    {
      question: 'Todos lo supieron antes de que yo __________.',
      answers: ['oyera', 'supiera', 'me enterara'],
      key: 2,
      type: 0,
      lessGood: 1,
    },
    {
      question:
        'Si ___________________, habrías sacado mejor calificación en el examen.',
      answers: [
        'hayas estudiado',
        'hubieras estudiado',
        'hubieses estudiando',
      ],
      key: 1,
      type: 0,
    },
    {
      question:
        'Como siempre __________ lo que piensa, a veces tiene problemas.',
      answers: ['dice', 'habla', 'cuenta'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Al ____________ al aeropuerto unos oficiales te revisan el pasaporte.',
      answers: ['estar entrando', 'entrado', 'entrar'],
      key: 2,
      type: 0,
    },
    {
      question:
        'Cuando _______________ el reporte, por favor dáselo a la secretaria.',
      answers: ['terminé', 'termines', 'termine'],
      key: 2,
      type: 0,
    }
  ]
  const types = ['Completa la frase con la mejor opción.',
  'Corrige los errores ortográficos de las siguientes palabras',
  'Lee el siguiente texto y selecciona la palabra correcta para completar el texto.']
  const [time, setTime] = useState(45);
  const [position, setPosition] = useState(0);
  const [progress, setProgress] = useState((time) * 100 / 45);
  const [start, setStarted] = useState(false);
  const [answered, setAnswered] = useState([]);
  const [aux, setAux] = useState(0);
  useEffect(() => {
    if (start === true) {
      if (aux === 0) {
        if (time > 0) {
          setTimeout(() => { setTime(time - 1) }, 1000)
        } else if (time === 0) {
          setPosition(position + 1);
          setTime(45);
        }
      } else if (aux === 1) {
        setTime(45)
        setAux(0)
      }
    }
    setProgress((time) * 100 / 45)
  }, [time, start]);


  useEffect(() => {
    if (start === true && position < (questions.length - 1)) {
      setPosition(position + 1)
    }
    if (answered.length === questions.length) {
      let score = answered.filter(answer => answer === 1).length;
      window.location.href='/spanish-advance/'+score;
    }


  }, [answered])
  return (
    <div className='quiz-container-lg' >
      <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
      <p className='question' hidden={!start}>{questions[position].question}</p>
      <div className='answers-lg' hidden={!start}>
        {
          questions[position].answers.map((answer, index) =>
            <div key={index} className='answer' onClick={() => {
              if (index === questions[position].key) {
                setAnswered(answered.concat(1))
              } else {
                setAnswered(answered.concat(0))
              }
              setAux(1)
            }}>
              {answer}
            </div>)
        }
      </div>
      <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
      <div className='quiz-progress'>{position + 1}/{questions.length}</div>
      <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
      <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
      <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
        setAnswered(answered.concat(0))
        setAux(1)
      }}>Next</button>
    </div>
  )
}

export default SpanishAdvanced