import React, { Fragment } from 'react'
import StudentNavBar from '../../components/student/StudentNavBar';
import StudentFinished from '../../components/student/StudentFinished';

const StdFinishedPage = () => {
  return (
    <Fragment>
      <StudentNavBar/>
      <StudentFinished/>
    </Fragment>
  )
}

export default StdFinishedPage