import React from 'react';
import { useTranslation } from 'react-i18next';
import Valeria from '../static/images/Profesores/Valeria.png';
import Martina from '../static/images/Profesores/Martina.png';
import Vera from '../static/images/Profesores/Vera.png';
import Matthew from '../static/images/Profesores/Matthew.png';

const AboutUs = () => {
  const {t}=useTranslation();
  return (
    <div className='bg-app d-flex justify-content-center pt-5 mt-2 ' >
            <div className='about-us-container'>
              <div className='about-us-text'>
                  <h2 className='my-3'>{t('about_us.title')}</h2>
                  <p className='my-4 '>
                    {t('about_us.p1')}
                  </p>
                  <p className='my-4 '>
                    {t('about_us.p2')}
                  </p>
                  <p className='my-4 '>
                    {t('about_us.p3')}
                  </p>
                  <p className='my-4 '>
                    {t('about_us.p4')}
                  </p>
                  <p className='my-5 mx-4'>
                    {t('about_us.footer')}
                  </p>
                  <p className='my-5 mx-4'>
                      {t('about_us.sign')}
                  </p>
              </div>
              <div className='about-us-images'>
                <div className='about-us-images-container'>
                  <div className='about-us-profile'>
                    <img src={Valeria} alt='img' />
                    <p>Valeria Sánchez Cruz</p>
                    <p>{t('about_us.spanish_teacher')}</p>
                  </div>
                  <div className='about-us-profile'>
                    <img src={Martina} alt='img' />
                    <p>Martina Di Maio</p>
                    <p>{t('about_us.italian_teacher')}</p>
                  </div>
                  <div className='about-us-profile'>
                    <img src={Vera} alt='img' />
                    <p>Vera Kolosova</p>
                    <p>{t('about_us.russian_teacher')}</p>
                  </div>
                  <div className='about-us-profile'>
                    <img src={Matthew} alt='img' />
                    <p>Matthew Klear</p>
                    <p>{t('about_us.english_teacher')}</p>
                  </div>
                  <div className='about-us-staff mt-5'>
                    <div className='persona my-2'>
                      <a href='https://www.instagram.com/pauu_li_/?hl=es'>Paula Martínez</a>
                      <p>{t('about_us.visual_artist')}</p>
                    </div>
                    <div className='persona my-2'>
                      <a href='www.andresrod.art'>Andrés Rodarte</a>
                      <p>{t('about_us.programmer')}</p>
                    </div>
                    <div className='persona my-2'>
                      <a href='densergon.github.io'>Daniel Serna</a>
                      <p>{t('about_us.programmer')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
  )
}

export default AboutUs