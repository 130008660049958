import React, { useState } from "react";
import GoogleButton from "react-google-button";
import { useTranslation } from "react-i18next";
import Background from "../../static/images/loginbackground.png";
import { googleSignUp, tchrRegister } from "../Functions";


const TeacherRegister = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [verified,setVerified]=useState(true);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value,
    });
    const pswd=document.getElementById('password');
    const vpswd=document.getElementById('vpassword');
    if(pswd.value!==""){
      if(pswd.value===vpswd.value){
        setVerified(false);
      }else{
        setVerified(true);
      }
    }
  };
  return (
    <div
      className="bg-app form-container"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="form-box white">
        <div className="d-flex justify-content-center">
          <h1 className="form-title">{t("login.signupt")}</h1>
        </div>
        <div className="m-top-3">
          <form onSubmit={(e)=>e.preventDefault()} id='form-tchrReg'>
          <div className="row mx-3 m-top-4">
            <label htmlFor="name" className="col-md-4 col-form-label">
              {t("login.name")}
            </label>
            <div className="col-md-8 ">
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mx-3 m-top-4">
            <label htmlFor="email" className="col-md-4 col-form-label">
              {t("login.email")}
            </label>
            <div className="col-md-8 ">
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mx-3 m-top-4">
            <label htmlFor="password" className="col-md-4 col-form-label">
              {t("login.password")}
            </label>
            <div className="col-md-8">
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mx-3 m-top-4">
            <label htmlFor="vpassword" className="col-md-4 col-form-label">
              {t("login.vpassword")}
            </label>
            <div className="col-md-8">
              <input
                type="password"
                className="form-control verified"
                name="vpassword"
                id="vpassword"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mx-3 m-top-3">
            <button className="btn btn-primary" onClick={() => tchrRegister(data)} disabled={verified}>
              {t("login.signupme")}
            </button>
          </div>
          </form>
        </div>
        <div className="d-flex justify-content-center">
          <GoogleButton type="light" label={t("login.googleup")} onClick={()=>googleSignUp('teacher')} />
        </div>
      </div>
    </div>
  )
}

export default TeacherRegister