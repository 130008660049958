import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const StdAccount = () => {
    const {t}=useTranslation();
  return (
    <div className='bg-app my-account-container'>
        <div className='container-md'>
        <h3 className='text-left'>{t('general.my-account')}</h3>
        </div>
        <div className='container-md py-3'>
            <div className='table-responsive'>
                <table className='table table-md table-hover align-middle'>
                    <tbody>
                        <tr>
                            <td>{t('general.idacc')}:</td>
                            <td>{Cookies.get('id')}</td>
                        </tr>
                        <tr>
                            <td>Nombre:</td>
                            <td>{Cookies.get('name')}</td>
                        </tr>
                        <tr>
                            <td>Correo Electronico:</td>
                            <td>{Cookies.get('email')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default StdAccount