import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt, FaSave, FaEraser } from "react-icons/fa";
import {db} from '../../firebase';
import { collection, deleteDoc, getDocs,doc, updateDoc, addDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

const AdminLevels = () => {
    const {t}=useTranslation();
    const [levels,setLevels]=useState([]);
    const getLevels=async()=>{
        const querySnapshot = await getDocs(collection(db, "levels"));
        setLevels([]);
        querySnapshot.forEach((doc) => {
            setLevels(last=>[...last,doc]);
        });
    }
    useEffect(()=>{
        getLevels()
    },[db]);
    const action=async()=>{
        const id=document.getElementById('id');
        const levelIpt=document.getElementById('level');
        if(id.value===""){
            //add
            await addDoc(collection(db,"levels"),{
                level:levelIpt.value
            }).then(()=>{
                clear();
                getLevels();
            });
        }else{
            //update
            const levelRef=doc(db,"levels",id.value);
            await updateDoc(levelRef,{
                level:levelIpt.value
            }).then(()=>{
                clear();
                getLevels();
            });
        }
    }

    const edit=(key,level)=>{
        document.getElementById('id').value=key;
        document.getElementById('level').value=level;
    }
    const clear=()=>{
        document.getElementById('level-form').reset();
    }
    const erase=async(key)=>{
        await deleteDoc(doc(db,'levels',key)).then(()=>{
            getLevels();
        })
    }
  return (
    <div className='admin-types-container'>
            <div className='container-md'>
                <div className='container-md'>
                    <div className='d-flex justify-content-center my-3'>
                        <h2>{t('general.levels')}</h2>
                    </div>
                    <form id='level-form' onSubmit={(e)=>e.preventDefault()}>
                        <div className='row my-2 mx-2'>
                            <label htmlFor='id' className='col-md-4'>Id:</label>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' name='id' id='id' readOnly/>
                            </div>
                        </div>
                        <div className='row my-2 mx-2'>
                            <label htmlFor='level' className='col-md-4'>{t('general.level')}:</label>
                            <div className='col-md-8'>
                                <input type='text' className='form-control' name='level' id='level' />
                            </div>
                        </div>
                        <div className='my-2 mx-2 d-flex justify-content-end'>
                            <button className='btn btn-secondary my-2 mx-1' onClick={clear}>{t('general.clear')} <FaEraser/> </button>
                            <button className='btn btn-primary my-2 mx-1' onClick={action} >{t('general.save')} <FaSave/> </button>
                        </div>
                    </form>
                </div>
               <div className='table-responsive'>
                <table className='table table-md table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'>{t('general.level')}</th>
                                <th scope='col'>{t('general.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                levels.map(level=>
                                    <tr key={level.id}>
                                        <td>{level.data().level}</td>
                                        <td>
                                            <button className='btn btn-warning mx-1 my-1' onClick={()=>edit(level.id,level.data().level)}><FaEdit/></button>
                                            <button className='btn btn-danger mx-1 my-1' onClick={()=>erase(level.id)}><FaTrashAlt/></button>
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
               </div>
            </div>
        </div>
  )
}

export default React.memo(AdminLevels)