import React,{Fragment} from 'react';
import NavigationBar from '../../components/NavigationBar';
import TeacherRegister from '../../components/teacher/TeacherRegister';
import Footer from '../../components/Footer';

const TeacherRegisterPage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <TeacherRegister/>
      <Footer/>
    </Fragment>
  )
}

export default TeacherRegisterPage