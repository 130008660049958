import React from 'react'
import { useParams } from 'react-router-dom'

const TeacherProfile = () => {
    const {id}=useParams();
  return (
    <div className='bg-app teacher-profile-container'>
        <div className='teacher-profile-photo'>
        </div>
        <div className='teacher-profile-content'>
            <h3 className='text-center py-2'>{id}</h3>
            <p className='py-3'>Do culpa tempor commodo in laboris et dolor veniam fugiat. Magna quis aliqua ea elit cillum eiusmod occaecat exercitation est. Deserunt et velit cillum excepteur reprehenderit sit non. Consequat reprehenderit officia id eiusmod cillum dolore non ipsum et aliqua deserunt. Quis enim dolor irure dolore ullamco.</p>
            <p className='py-3'>
                Reprehenderit Lorem labore consectetur exercitation excepteur dolor anim. Ipsum eiusmod ad cillum duis consectetur irure nisi pariatur. Et dolor aliquip nisi eiusmod incididunt anim non adipisicing nostrud magna est ullamco sunt. Minim occaecat ut irure voluptate. Irure adipisicing irure esse nisi esse aliquip exercitation do velit eu aute sunt. Non consectetur dolor minim exercitation eiusmod est excepteur nostrud cupidatat pariatur nisi laborum.
            </p>
        </div>
    </div>
  )
}

export default TeacherProfile