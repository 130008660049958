import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {db} from '../../firebase';
import {query,where,getDocs,collection} from 'firebase/firestore';
import Cookies from 'js-cookie';

const StudentActive = () => {
  const {t}=useTranslation();
  const [courses,setCourses]=useState([]);
  const getCourses = async () => {
    const q = query(collection(db, "courses"), where('students.id', '==',Cookies.get('id')),where('status', 'in', ['ready', 'started'])); //OR
    const querySnapshot = await getDocs(q);
    setCourses([])
    querySnapshot.forEach((doc) => {
      setCourses(last => [...last, doc])
    });
  }
  useEffect(()=>{
    getCourses();
  },[db])
  return (
    <div className='bg-app std-height pt-5 mt-5'>
      <div className='container-md'>
        <div className='table-responsive'>
          <table className='table table-hover table-md align-middle'>
            <thead>
              <tr>
                <th scope='col'>{t('general.language')}</th>
                <th scope='col'>{t('general.level')}</th>
                <th scope='col'>{t('general.type')}</th>
                <th scope='col'>{t('general.teacher')}</th>
                <th scope='col'>{t('general.dateStart')}</th>
                <th scope='col'>{t('general.dateEnd')}</th>
              </tr>
            </thead>
            <tbody>
              {
                courses.map(course=> <tr key={course.id}>
                  <td>{course.data().language}</td>
                  <td>{course.data().level}</td>
                  <td>{course.data().type}</td>
                  <td>{course.data().teacher}</td>
                  <td>{course.data().dateStart}</td>
                  <td>{course.data().dateEnd}</td>
                </tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default React.memo(StudentActive)