import React, { useCallback } from 'react';
import VALERIA_IMG from '../../static/images/Profesores/Valeria.png';
import { WHITE } from '../Colors';

const TeacherInfo = ({ info, className }) => {
	const getImage = useCallback(
		/**
		 *
		 * @param {string} name
		 */
		(name) => {
			switch (name) {
				case 'Valeria Sánchez':
					return VALERIA_IMG;
				default:
					return null;
			}
		},
		[]
	);

	return (
		<div
			style={{
				display: 'grid',
				alignItems: 'center',
				justifyContent: 'center',
				columnGap: '10px',
				gridTemplateColumns: 'auto',
				gridTemplateRows: 'auto',
				gridTemplateAreas: `
                    'photo'
                    'name'
                    'mail'
                   `,
				borderBottom: '1px solid grey',
				padding: '10px 0',
				backgroundColor: WHITE,
			}}
		>
			<span
				style={{
					gridArea: 'photo',
					display: 'flex',
					flexFlow: 'column',
					alignItems: 'center',
				}}
			>
				<img
					src={getImage(info.name) || info.photo}
					alt='teacher'
					style={{
						clipPath: 'circle(50%)',
						height: '150px',
					}}
				/>
			</span>
			<span
				style={{
					gridArea: 'name',
					display: 'flex',
					flexFlow: 'column',
					alignItems: 'center',
				}}
			>
				<label htmlFor='teacher-name'>
					{' '}
					{`Teacher for ${className}`}{' '}
				</label>
				<h5 name='teacher-name'>{info.name}</h5>
			</span>
			<span
				style={{
					gridArea: 'mail',
					display: 'flex',
					flexFlow: 'column',
					alignItems: 'center',
				}}
			>
				<label htmlFor='teacher-mail'> Mail </label>
				<h5 name='teacher-mail'>{info.email}</h5>
			</span>
		</div>
	);
};

export default TeacherInfo;
