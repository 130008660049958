import React, { useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import { useTranslation } from "react-i18next";
import Background from "../static/images/loginbackground.png";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import {logIn,googleSignIn } from './Functions';
import {FaEye,FaEyeSlash} from 'react-icons/fa';
import { redirectIn } from "./Functions";
const Login = () => {
  const { t } = useTranslation();
  const [data,setData]=useState();
  const [eye,setEye]=useState();
  const [password,setPassword]=useState("password");
  const [forgot,setForgot]=useState(false);
  const handleChange=(evt)=>{
    const value=evt.target.value;
    setData({
      ...data,
      [evt.target.name]:value
    })
  }
  const visiblePassword=()=>{
    password==="password"?setPassword("text"):setPassword("password");
  }
  useEffect(()=>{redirectIn()},[])
  useEffect(()=>{
    if(password==="password"){
      setEye(<FaEye/>);
    }else{
      setEye(<FaEyeSlash/>);
    }
  },[password])
  return (
    <div
      className="bg-app form-container"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="form-box white">
        <div className="d-flex justify-content-center">
          <h1 className="form-title">{t('login.login')}</h1>
        </div>
        <div className="m-top-3">
          <div className="row mx-3 m-top-4">
            <label htmlFor="email" className="col-form-label">
              {t('login.email')}
            </label>
            <div className="input-group">
              <input type="text" className="form-control" name="email" onChange={handleChange} required/>
            </div>
          </div>
          <div className="row mx-3 m-top-4 ">
            <label htmlFor="password" className="col-form-label">
              {t('login.password')}
            </label>
            <div className="input-group">
              <input type={password} className="form-control" name="password" onChange={handleChange} required/>
              <span className="input-group-text" onClick={visiblePassword}>{eye}</span>
            </div>
            
          </div>
          <div className="row mx-3 m-top-3">
            <button className="btn btn-primary" onClick={()=>logIn(data)}>{t('login.login')}</button>
          </div>
          <div className="mx-4 m-top-3">
              <p onClick={()=>setForgot(!forgot)}>{t('login.forgot')}</p>
              <Fade top collapse when={forgot} >
                  <div className="forgot">
                    <div className="line"></div>
                    <p className="mt-2">Ingresa tu correo electronico para enviar un código.</p>
                    <div className="row">
                      <div className="col-sm-7">
                      <input type='text' className='form-control' placeholder="example@example.com"/>
                      </div>
                      <div className="col-sm-5 my-1">
                        <button className="btn btn-primary mt-1 mx-1">Enviar código</button>
                        <button className="btn btn-secondary mt-1 mx-1">Ya tengo el código.</button>
                      </div>
                    </div>
                  </div>
                  
              </Fade>
              <Fade top collapse when={!forgot} >
              <p>{t('login.regis')}<NavLink to='/student-register' className="white">{t('login.here')}</NavLink></p>
              </Fade>
              
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <Fade top collapse when={!forgot} >
          <GoogleButton type="light" label={t('login.google')} onClick={googleSignIn}/>
        </Fade>
        </div>
      </div>
    </div>
  );
};

export default Login;
