import React, { Fragment } from 'react';
import StudentNavBar from '../../components/student/StudentNavBar';
import StudentActive from '../../components/student/StudentActive'

const StdActivePage = () => {
  return (
    <Fragment>
        <StudentNavBar/>
        <StudentActive/>
    </Fragment>
  )
}

export default StdActivePage