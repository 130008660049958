import React, { Fragment, useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const SpanishBeginner = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question: '¿Cómo se llama su madre?',
            answers: [
                'Mi madre se llama María',
                'Su madre se llama Roberta.',
            ],
            key: 1,
            type: 0,
        },
        {
            question: '¿De dónde es tu amigo Frederick?',
            answers: ['Frederick es de Alemania.', 'Mi amigo alemán.'],
            key: 0,
            type: 0,
        },
        {
            question: '¿Cuántos idiomas hablas?',
            answers: [
                'Yo hablo inglés, noruego y un poco de español.',
                'Yo hable inglés, noruego y un poco de español.',
            ],
            key: 0,
            type: 0,
        },
        {
            question: '¿A qué hora empieza nuestra clase de español?',
            answers: [
                'Empieza a 10 por la mañana.',
                'Empieza a las 10 de la mañana.',
            ],
            key: 1,
            type: 0,
        },
        {
            question: '¿Qué les gusta hacer los fines de semana?',
            answers: [
                'A nosotros nos gustan los comer en restaurantes e ir al parque.',
                'A ellos les gusta comer en restaurantes, ir al parque y salir a bailar.',
            ],
            key: 1,
            type: 0,
        },
        {
            question: '¿Cuáles son las estaciones del año?',
            answers: [
                'Las estaciones son primavera, verano, autuno e inverno.',
                'Las estaciones son primavera, verano, otoño e invierno.',
            ],
            key: 1,
            type: 0,
        },
        {
            question: '¿_______ es tu actor favorito?',
            answers: ['Qué', 'Cómo', 'Quién'],
            key: 2,
            type: 1,
        },
        {
            question: 'No quiero ________ sopa, no tengo hambre.',
            answers: ['nada de', 'cualquier', 'ningún'],
            key: 0,
            type: 1,
        },
        {
            question:
                'Me encanta viajar _______ avión porque es cómodo y rápido.',
            answers: ['a', 'en', 'a través'],
            key: 2,
            type: 1,
        },
        {
            question:
                'Ayer compré tres kilos de naranjas _______ 45 pesos.',
            answers: ['hacia', 'en', 'asia'],
            key: 1,
            type: 1,
        },
        {
            question:
                'Mis amigos y yo odiamos llegar tarde ______ clase.',
            answers: ['a', 'en', 'hacia'],
            key: 0,
            type: 1,
        },
        {
            question:
                'El otro día ellos fueron _______ la panadería ________ comprar un gran pastel ________ estaban celebrando el cumpleaños de su abuelo.',
            answers: ['a, en, sin', 'a, a, en', 'a, a, porque'],
            key: 2,
            type: 1,
        },
        {
            question: '________ que vivo en Perú soy mucho más feliz.',
            answers: ['Desde', 'Desde hace', 'Durante'],
            key: 0,
            type: 1,
        },
        {
            question:
                'Cuando _______ en Argentina, _________ muchos museos.',
            answers: [
                'estaba, visité',
                'estoy, visitaba',
                'estaba, visito',
            ],
            key: 0,
            type: 1,
        },
        {
            question:
                'Pedro trabajó mucho ________ 6 meses ________ poder ir al concierto de Manu Chao en Cuba.',
            answers: ['en, por', 'entre, para', 'durante, para'],
            key: 2,
            type: 1,
        },
        {
            question: '¿Cuándo trabajaba, era rico?',
            answers: [
                'No, pero tenía dinero para viajar.',
                'Si, ganaba suficiente dinero y viajaba.',
            ],
            key: 0,
            type: 2,
        },
        {
            question: '¿Cuántos años viajó por Latinoamérica?',
            answers: ['Menos de 5 años.', 'Más de 5 años.'],
            key: 1,
            type: 2,
        },
        {
            question:
                '¿Qué edad tenía cuando empezó a estudiar español?',
            answers: ['Tenía 25 años.', 'Tuvo 25 años.'],
            key: 0,
            type: 2,
        },
        {
            question: '¿Ahora tiene familia? ',
            answers: [
                'Si, ahora tendrá una familia pequeña.',
                'Si, ahora tiene dos hijas.',
            ],
            key: 1,
            type: 2,
        },
        {
            question: '¿Qué harán pronto? ',
            answers: [
                'Ellos venderán su casa y se mudarán a Quito.',
                'Venderemos nuestra casa y nos mudaremos a Quito.',
            ],
            key: 0,
            type: 2,
        },
    ]
    const types = [
        'Escoge la respuesta correcta',
        'Completa la frase con la mejor opción',
        'Lee el siguiente texto y responde las preguntas: \nEn el 2011 tuve mi primer trabajo, no me gustaba nada, pero ganaba dinero suficiente para poder viajar por Latinoamérica. Desde el 2011 hasta el 2018 logré conocer 7 países diferentes entre esos Chile, Bolivia, Costa Rica, México y Panamá. Empecé a estudiar español a los 25 años, porque conocí al amor de mi vida. Nos casamos en el 2017 y ahora tenemos 2 hijas. Pronto mi familia y yo venderemos nuestra casa y nos mudaremos a Quito en Ecuador.',
    ]
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);
    
    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        } 
            if(answered.length===questions.length){
                let score = answered.filter(answer => answer === 1).length;
            if (score < 7) {
                alert('Tu nivel es A0');
                window.location.href='/spanish-advance';
            } else if (score >= 7 && score < 14) {
                alert('Tu nivel es A1');
                window.location.href='/quiz-selection';
            } else if (score >= 14) {
                alert('Tu nivel es A2-');
                window.location.href='/quiz-selection';
            }
            }
            
        
    }, [answered])

    return (
        <Fragment>
            <div className='quiz-container' >
                <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
                <p className='question' hidden={!start}>{questions[position].question}</p>
                <div className='answers' hidden={!start}>
                    {
                        questions[position].answers.map((answer, index) =>
                            <div key={index} className='answer' onClick={() => {
                                if (index === questions[position].key) {
                                    setAnswered(answered.concat(1))
                                } else {
                                    setAnswered(answered.concat(0))
                                }
                                setAux(1)
                            }}>
                                {answer}
                            </div>)
                    }
                </div>
                <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
                <div className='quiz-progress'>{position + 1}/{questions.length}</div>
                <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
                <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
                <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                    setAnswered(answered.concat(0))
                    setAux(1)
                }}>Next</button>
            </div>
        </Fragment>
    )
}

export default SpanishBeginner