import { getDocs, collection, query, where } from 'firebase/firestore';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../../firebase';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEye } from 'react-icons/fa';

const TeacherFinished = () => {
    const { t } = useTranslation();
    const [courses, setCourses] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [data,setData]=useState({
        id:'',
        data:{
          dateEnd:'',
          dateStart:'',
          duration:'',
          schedule:[
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            },
            {
              class:false,
              starts:'',
              ends:''
            }
          ],
          level:'',
          price:'',
          teacher:'',
          type:'',
          students:[]
        }});
    useEffect(()=>{
        show===true?setShow(false):setShow(true);
    },[data]);
    const getCourses = async () => {
        const q = query(collection(db, "courses"), where('status', '==', 'finished')); //OR
        const querySnapshot = await getDocs(q);
        setCourses([]);
        querySnapshot.forEach((doc) => {
            setCourses(last => [...last, doc]);
        });
    }
    useEffect(() => {
        setShow(false)
        getCourses();
    }, [db]);
    return (
        <div className='bg-app std-height pt-5'>
            <div className='container-md pt-4'>
                <h3>{t('general.historical')}</h3>
                <div className='table-responsive'>
                    <table className='table table-md table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'>{t('general.language')}</th>
                                <th scope='col'>{t('general.level')}</th>
                                <th scope='col'>{t('general.type')}</th>
                                <th scope='col'>{t('general.dateStart')}</th>
                                <th scope='col'>{t('general.dateEnd')}</th>
                                <th scope='col'>{t('general.duration')}</th>
                                <th scope='col'>{t('general.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courses.filter(c => c.data().teacher.name === Cookies.get('name')).map(course =>
                                    <tr key={course.id}>
                                        <td>{course.data().language}</td>
                                        <td>{course.data().level}</td>
                                        <td>{course.data().type}</td>
                                        <td>{course.data().dateStart}</td>
                                        <td>{course.data().dateEnd}</td>
                                        <td>{course.data().duration} hrs</td>
                                        <td>
                                        <button className='btn btn-primary my-1 mx-1'  onClick={()=>setData({id:course.id,data:course.data()})}><FaEye /></button>
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{data.data.language}-{data.data.level}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Students</h4>
          <table className='table table-md table-hover'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
              </tr>
            </thead>
            <tbody>
              {
                data.data.students.map(std=>
                  <tr key={std.id}>
                    <td>{std.name}</td>
                    <td>{std.email}</td>
                  </tr>)
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    )
}

export default TeacherFinished