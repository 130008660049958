import React, { useEffect} from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { logOut } from '../Functions';
import Logo from '../../static/images/Skazki.png';
import { useTranslation } from 'react-i18next';
import { FaHome, FaCog } from 'react-icons/fa';
import {redirect} from '../Functions';


const AdminNavBar = () => {
    const { t, i18n } = useTranslation();
    useEffect(()=>redirect(),[]);
    return (
        <Navbar className='fixed-top nav-styles' expand='lg'>
            <Container>
                <NavLink className='navbar-brand' to='/'>
                    <img src={Logo} alt='logo' style={{ height: "4vh" }} />
                </NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className='ms-auto nav-links'>
                        <NavLink to='/admin' className='nav-link'><FaHome size={25} /></NavLink>
                        <NavLink to='/admin/languages' className='nav-link'>{t('navbar.languages')}</NavLink>
                        <NavLink to='/admin/levels' className='nav-link'>{t('navbar.levels')}</NavLink>
                        <NavLink to='/admin/teachers' className='nav-link'>{t('navbar.teachers')}</NavLink>
                        <NavLink to='/admin/types' className='nav-link'>{t('navbar.types')}</NavLink>
                        <NavDropdown title={t('navbar.courses')}>
                                <NavLink to='/admin/active' className='nav-link'>
                                    {t('navbar.active')}
                                </NavLink>
                                <NavLink to='/admin/finished' className='nav-link'>
                                    {t('navbar.finished')}
                                </NavLink>
                        </NavDropdown>
                        <NavDropdown title={<FaCog size={20} />}>
                            <NavDropdown.Item onClick={() => i18n.changeLanguage("en")}>
                                <p
                                    style={{
                                        fontWeight:
                                            i18n.resolvedLanguage === "en" ? "bold" : "normal",
                                    }}
                                >
                                    English
                                </p>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => i18n.changeLanguage("es")}>
                                <p
                                    style={{
                                        fontWeight:
                                            i18n.resolvedLanguage === "es" ? "bold" : "normal",
                                    }}
                                >
                                    Español
                                </p>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => logOut()}>
                                Cerrar Sesión
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default AdminNavBar