import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {db} from '../firebase'
import { doc, updateDoc, arrayUnion} from "firebase/firestore";

const Success = () => {
    const {id}=useParams();
    const execute=async()=>{
        const courseRef = doc(db, "courses", id);
        await updateDoc(courseRef, {
            students: arrayUnion({
                id:Cookies.get('id'),
                name:Cookies.get('name'),
                email:Cookies.get('email')
            })
        }).then(()=>{
            Cookies.remove('pending');
            window.location.href='/student';
        });

    }
    useEffect(()=>{
        if(Cookies.get('id')!==undefined){
            if(Cookies.get('type')==='student'){
                execute()
            }
        }else{
            window.location.href='/';
        }
        
    },[])
  return (
    <div>{id}</div>
  )
}

export default Success