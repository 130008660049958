import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminLevels from '../../components/admin/AdminLevels';

const AdminLevelsPage = () => {
  return (
    <Fragment>
        <AdminNavBar/>
        <AdminLevels/>
    </Fragment>
  )
}

export default AdminLevelsPage