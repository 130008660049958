import React from 'react';
import { useTranslation } from 'react-i18next';

const Offer = () => {
    const { t } = useTranslation();
    return (
            <div className='offer-container bg-app'>
                <div className='offer-title-container'>
                <h2>{t('offer.title')}</h2>
                </div>
                <div className='offer'>
                    <div id='esp'>
                        <h2>{t('languages.spanish.short')}</h2>
                    </div>
                    <div id='ing'>
                        <h2>{t('languages.english.short')}</h2>
                    </div>
                    <div id='ita'>
                        <h2>{t('languages.italian.short')}</h2>
                    </div>
                    <div id='rus'>
                        <h2>{t('languages.russian.short')}</h2>
                    </div>
                    <div id='ale'>
                        <h2>{t('languages.german.short')}</h2>
                    </div>
                    <div id='tur'>
                        <h2>{t('languages.turquish.short')}</h2>
                    </div>
                    <div id='fra'>
                        <h2>{t('languages.french.short')}</h2>
                    </div>
                </div>
            </div>
    )
}

export default Offer