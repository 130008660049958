import React from 'react';
import { Navbar,Nav,Container,NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCog, FaHome } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { logOut } from '../Functions';
import Logo from '../../static/images/Skazki.png';

const TeacherNavBar = () => {
    const {t,i18n}=useTranslation();
  return (
    <Navbar className='nav-styles fixed-top'>
        <Container>
            <NavLink to='/' className='navbar-brand'>
                <img src={Logo} alt='logo' style={{ height: "4vh" }} />
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className='ms-auto nav-links pe-5'>
                    <NavLink to='/teacher' className='nav-link'><FaHome size={25} /> </NavLink>
                    <NavDropdown title={t('navbar.courses')}>
                                <NavLink to='/teacher/active' className='nav-link'>
                                    {t('navbar.active')}
                                </NavLink>
                            
                                <NavLink to='/teacher/finished' className='nav-link'>
                                    {t('navbar.finished')}
                                </NavLink>
                        </NavDropdown>
                    <NavDropdown title={<FaCog size={20} />}>
                            <NavDropdown.Item onClick={() => i18n.changeLanguage("en")}>
                                <p
                                    style={{
                                        fontWeight:
                                            i18n.resolvedLanguage === "en" ? "bold" : "normal",
                                    }}
                                >
                                    English
                                </p>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => i18n.changeLanguage("es")}>
                                <p
                                    style={{
                                        fontWeight:
                                            i18n.resolvedLanguage === "es" ? "bold" : "normal",
                                    }}
                                >
                                    Español
                                </p>
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={() => logOut()}>
                                Cerrar Sesión
                            </NavDropdown.Item>
                        </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}

export default TeacherNavBar