import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from '../static/images/Skazki.png';
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaFacebookSquare,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-center text-lg-start bg-gray text-gray footer-shadow w-100">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>{t("footer.social")}</span>
        </div>
        <div>
          <a
            href="https://www.wa.link/3eorhw"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4 text-reset"
          >
            <FaWhatsapp size={40} />
          </a>
          <a
            href="https://www.instagram.com/skazki.studio/"
            className="me-4 text-reset"
          >
            <FaInstagram size={40} />
          </a>
          <a href="/" className="me-4 text-reset">
            <FaFacebookSquare size={40} />
          </a>
        </div>
      </section>
      <section className="/">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img src={Logo} alt='logo' className="me-3 logo" />
              </h6>
              <p className="mx-auto">{t("footer.description")}</p>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("footer.links")}
              </h6>
              <p>
                <NavLink to="/aboutUs" className="text-reset">
                  {t('footer.aboutus')}
                </NavLink>
              </p>
              <p>
                <NavLink to="/teacher-register" className="text-reset">
                  {t('footer.rteacher')}
                </NavLink>
              </p>
            </div>
            <div className="col-md-4 col-lg-5 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-3">
                {t("footer.contact")}
              </h6>
              <p>
                {t("footer.address")}: <br />
                Andador 26 Int. 23 Coyoacán 04800 Ciudad de México, México
              </p>
              <p>
                <FaRegEnvelope className="me-3" />
                center.skazki.studio@gmail.com
              </p>
              <p>
                <FaPhoneAlt className="me-3" />+ 52 55 8330 5219
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center p-4">
        © 2022 Copyright:
        <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
          skazkistudio.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;
