import React, { Fragment } from 'react';
import StudentNavBar from '../../components/student/StudentNavBar';
import StdAccount from '../../components/student/StdAccount';

const StdAccountPage = () => {
    return (
   <Fragment>
    <StudentNavBar/>
    <StdAccount/>
   </Fragment>
  )
}

export default StdAccountPage