import React from 'react'
import { NavLink } from 'react-router-dom'

const EnglishLevels = () => {
    return (
        <div className='bg-app std-height mt-5 pt-5'>
            <h2 className='text-center'>Levels</h2>
            <div className='d-flex flex-column'>
                <NavLink to='/english-beginner' className='language-button'>Beginner</NavLink>
                <NavLink to='/english-intermediate' className='language-button'>Intermediate</NavLink>
                <NavLink to='/english-upperintermediate' className='language-button'>Upper intermediate - Advanced</NavLink>
            </div>
        </div>
    )
}

export default EnglishLevels