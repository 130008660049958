import React,{Fragment} from 'react';
import NavigationBar from '../../components/NavigationBar';
import StudentRegister from '../../components/student/StudentRegister';
import Footer from '../../components/Footer';

const StudentRegisterPage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <StudentRegister/>
      <Footer/>
    </Fragment>
  )
}

export default StudentRegisterPage