import React, { Fragment,useState,useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const SpanishIntermediate = () => {
  const {t} = useTranslation();

  const questions=[
    {
      question: 'Si vas a la fiesta, seguramente ________ mucho.',
      answers: ['bailarás', 'harás'],
      key: 0,
      type: 0,
    },
    {
      question:
        '_________ vacaciones en verano y __________ recorrer Tailandia.',
      answers: ['Habrá, debiéramos', 'Tendremos, nos gustaría'],
      key: 1,
      type: 0,
    },
    {
      question:
        'Te voy a dar un consejo, __________ comer más frutas y verduras.',
      answers: ['deberías', 'tendrías'],
      key: 0,
      type: 0,
    },
    {
      question:
        '_________ no alcanzamos boletos del cine, fuimos a cenar comida italiana.',
      answers: ['Como', 'Cómo'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Hubo demasiados accidentes de carros ____________ las fuertes lluvias.',
      answers: ['a causa de', 'sin'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Usualmente en la Ciudad de México ____________ pero estos días __________ nublado y frío.',
      answers: ['hace frio/ha hecho', 'esta frio/estando'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Para ser un jugador de tenis profesional _________ entrenar todos los días.',
      answers: ['sugerí', 'hay que'],
      key: 1,
      type: 0,
    },
    {
      question: 'Antes de _______, piensa dos veces.',
      answers: ['actuar', 'escribiendo'],
      key: 0,
      type: 0,
    },
    {
      question: 'Estoy muy cansada, _______ a la cama.',
      answers: ['ando', 'me iré'],
      key: 1,
      type: 0,
    },
    {
      question: '¡Jamás _______ una novela romántica!',
      answers: ['leíste', 'leístes'],
      key: 0,
      type: 0,
    },
    {
      question: '¿Alguna vez has estado en Francia?',
      answers: [
        'Jamás había estado, pero me encantará.',
        'Jamás he estado, pero me encantaría.',
      ],
      key: 1,
      type: 1,
    },
    {
      question: '¿Cómo deberíamos solucionar este problema?',
      answers: [
        'Deberíamos primer tener una reunión para discutir nuestros puntos de vista.',
        'Debimos llamar por teléfono a nuestro jefe.',
      ],
      key: 0,
      type: 1,
    },
    {
      question: '¿Cuándo llamaste a María?',
      answers: ['En cuánto llegué a casa.', 'Así por la tarde.'],
      key: 0,
      type: 1,
    },
    {
      question:
        '¿Si no encuentro trabajo desde hace meses qué consejo me das?',
      answers: [
        'Te aconsejo que mandes tu CV a todas las empresas que conozcas.',
        'Te aconsejo que mandas tu CV a todas las empresas que sepas.',
      ],
      key: 0,
      type: 1,
    },
    {
      question:
        '¿Qué es lo que más te molesta que la gente haga?',
      answers: [
        'Me molesta que hablen alto y no sean amables.',
        'Me molesta que hacen cosas sin sentido.',
      ],
      key: 0,
      type: 1,
    },
    {
      question: '¿Qué banco le hizo el préstamo a la Sra. Cole?',
      answers: [
        'Un banco de Nueva Gales del Sur.',
        'Fue el banco ANZ.',
      ],
      key: 1,
      type: 2,
    },
    {
      question: '¿Cuál ha sido siempre su gran ilusión?',
      answers: [
        'Siempre ha sido tener una casa propia.',
        'Siempre he sido tener una casa propia.',
      ],
      key: 0,
      type: 2,
    },
    {
      question: '¿Con ayuda de quién solicitó la hipoteca?',
      answers: [
        'Solicitó la hipoteca ayudando a su hija.',
        'Solicitó la hipoteca con ayuda de su hija.',
      ],
      key: 1,
      type: 2,
    },
    {
      question:
        'Si la señora hubiese tenido 20 años menos al momento de solicitar la hipoteca ¿cuántos años tendría al terminar de pagar el préstamo?',
      answers: [
        'La señoría tendría 119 años.',
        'La señoría tendría 102 años.',
      ],
      key: 1,
      type: 2,
    },
    {
      question: '¿Qué es lo más sorprendente de la noticia?',
      answers: [
        'Que la gente del banco fue muy amable con la señora.',
        'Que la señora logró obtener un préstamo hipotecaria a su avanzada edad.',
      ],
      key: 1,
      type: 2,
    },
  ];
const types = [
    'Escoge la respuesta correcta',
    'Completa la frase con la mejor opción',
    `Lee la siguiente noticia y responde las preguntas:Un banco de Australia ha 
				concedido a una señora de 92 años una hipoteca a 30 años para comprar su primera  
				vivienda. Margaret Cole, la beneficiaria de la hipoteca, tendría 122 años si vive 
				para terminar de pagar su préstamo al banco ANZ. Con la concesión de esta ayuda de 
				2.500 libras se ha convertido en la compradora de una primera vivienda más anciana  
				de Nueva Gales del Sur. M. Cole, que ha comprado una casa de campo de tres habitaciones  
				en la ciudad de Watanobbi, dijo que hará todo lo que pueda para pagarla. Desde que en  
				1976 emigró desde Gales, Gran Bretaña a Australia, ha alquilado casas y apartamentos  
				en Sidney, en la Costa Este y en la Costa Central. Sin embargo, su gran ilusión ha  
				sido siempre tener su propia casa. Por ello solicitó la hipoteca, y ha sido la propia 
				hija de M. Cole quien ha firmado todos los avales. "Nunca pensé que podría tener mi 
				propia casa. En el banco han sido muy amables al dejarme el dinero", manifestó la 
				señora Cole.
				`]
const [time, setTime] = useState(40);
const [position, setPosition] = useState(0);
const [progress, setProgress] = useState((time) * 100 / 40);
const [start, setStarted] = useState(false);
const [answered, setAnswered] = useState([]);
const [aux, setAux] = useState(0);
useEffect(() => {
    if (start === true) {
        if (aux === 0) {
            if (time > 0) {
                setTimeout(() => { setTime(time - 1) }, 1000)
            } else if (time === 0) {
                setPosition(position + 1);
                setTime(40);
            }
        } else if (aux === 1) {
            setTime(40)
            setAux(0)
        }
    }
    setProgress((time) * 100 / 40)
}, [time, start]);


useEffect(() => {
    if (start === true && position < (questions.length - 1)) {
        setPosition(position + 1)
    } 
        if(answered.length===questions.length){
            let score = answered.filter(answer => answer === 1).length;
        if (score < 7) {
            alert('Tu nivel es A2-');
            window.location.href='/quiz-selection';
        } else if (score >= 7 && score < 13) {
            alert('Tu nivel es A2+');
            window.location.href='/quiz-selection';
        } else if (score >= 13) {
            alert('Tu nivel es B1-');
            window.location.href='/quiz-selection';
        }
        }
        
    
}, [answered])
  return (
   <Fragment>
     <div className='quiz-container' >
                <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
                <p className='question' hidden={!start}>{questions[position].question}</p>
                <div className='answers' hidden={!start}>
                    {
                        questions[position].answers.map((answer, index) =>
                            <div key={index} className='answer' onClick={() => {
                                if (index === questions[position].key) {
                                    setAnswered(answered.concat(1))
                                } else {
                                    setAnswered(answered.concat(0))
                                }
                                setAux(1)
                            }}>
                                {answer}
                            </div>)
                    }
                </div>
                <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
                <div className='quiz-progress'>{position + 1}/{questions.length}</div>
                <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
                <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
                <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                    setAnswered(answered.concat(0))
                    setAux(1)
                }}>Next</button>
            </div>
   </Fragment>
  )
}

export default SpanishIntermediate