import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaWhatsapp, FaInstagram, FaGlobeAmericas, FaHome } from "react-icons/fa";
import Logo from "../static/images/Skazki.png";
import Mex from "../static/images/mex.png";
import Usa from "../static/images/eua.png";
import Cookies from "js-cookie";

const NavigationBar = () => {
  const { t, i18n } = useTranslation();
  const [visible,setVisible]=useState(false);
  useEffect(()=>{
    if(Cookies.get('type')===undefined){
      setVisible(false)
    }else{
      setVisible(true);
    }
  },[]);
  return (
    <Navbar className="fixed-top nav-styles" expand="md">
      <Container>
        <NavLink to="/" className="navbar-brand">
          <img src={Logo} alt="logo" style={{ height: "4vh" }} />
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="ms-auto nav-links">
            <Nav.Link href="https://wa.me/5215583305219">
              <FaWhatsapp size={30} className="black" />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/skazki.studio/?hl=es">
              <FaInstagram size={30} className="black" />
            </Nav.Link>
            <NavLink to={`/${Cookies.get('type')}`} className='nav-link' hidden={!visible}>
              <FaHome size={30} className='black'/>
            </NavLink>
            <NavLink to="/courses/0" className="nav-link">
              {t("navbar.courses")}
            </NavLink>
            <NavLink to="/aboutUs" className="nav-link">
              {t("navbar.aboutus")}
            </NavLink>
            <NavLink to="/login" className="nav-link" hidden={visible}>
              {t("navbar.login")}
            </NavLink>
            <NavDropdown
              title={
                <div style={{ display: "inline-block" }}>
                  <FaGlobeAmericas size={20} />
                </div>
              }
              drop='down'
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item>

                <p
                  style={{
                    fontWeight:
                      i18n.resolvedLanguage === "en" ? "bold" : "normal",
                  }}
                  onClick={() => i18n.changeLanguage("en")}
                >
                  <img src={Usa} alt='usa flag' className="flag-ico" />
                  English
                </p>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>

                <p
                  style={{
                    fontWeight:
                      i18n.resolvedLanguage === "es" ? "bold" : "normal",
                  }}
                  onClick={() => i18n.changeLanguage("es")}
                >
                  <img src={Mex} alt='usa flag' className="flag-ico" />
                  Español
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
