import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../../static/images/Skazki.png';
import Cookies from 'js-cookie';
import { logOut } from '../Functions';
import { FaHome } from 'react-icons/fa';

const StudentNavBar = () => {
    return (
        <Navbar className='fixed-top nav-styles' expand="sm">
            <Container>
                <NavLink to="/" className="navbar-brand">
                    <img src={Logo} alt="logo" style={{ height: "4vh" }} />
                </NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className='ms-auto nav-links'>
                        <NavLink to='/student' className='nav-link'>
                            <FaHome size={30}/>
                        </NavLink>
                       <NavDropdown title='Cursos'>
                       <NavLink to='/student/active' className='nav-link'>
                            Activos
                        </NavLink>
                        <NavDropdown.Divider/>
                        <NavLink to='/student/finished' className='nav-link'>
                            Finalizados
                        </NavLink>
                       </NavDropdown>
                        <NavDropdown
                            title={<div style={{backgroundImage:`url(${Cookies.get('photo')})` }} className='user-profile-pic'></div>}
                        >
                            <NavLink to='/std-account' className='nav-link'>Mi cuenta</NavLink>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={logOut}>
                                Cerrar Sesión
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default StudentNavBar