import { getDocs, collection, query, where, addDoc, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaEraser, FaEye, FaRedo, FaSave, FaTrashAlt } from 'react-icons/fa';
import { db } from '../../firebase';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {arrayUnion} from 'firebase/firestore'

const AdminCoursesActive = () => {
  const { t } = useTranslation();
  const [course,setCourse]=useState({
    id:'',
    data:{
      dateEnd:'',
      dateStart:'',
      duration:'',
      schedule:[
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        }
      ],
      level:'',
      price:'',
      teacher:'',
      type:'',
      students:[]
    }});
    const [data, setData] = useState();
    const [languages, setLanguages] = useState([]);
    const [tchr, setTchr] = useState();
    const [levels, setLevels] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [types, setTypes] = useState([]);
    const [courses, setCourses] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  
  const getLanguages = async () => {
    const querySnapshot = await getDocs(collection(db, "languages"));
    setLanguages([]);
    querySnapshot.forEach((doc) => {
      setLanguages(last => [...last, doc]);
    });
  }
  const getLevels = async () => {
    const querySnapshot = await getDocs(collection(db, "levels"));
    setLevels([]);
    querySnapshot.forEach((doc) => {
      setLevels(last => [...last, doc]);
    });
  }
  useEffect(()=>{
    show===true?setShow(false):setShow(true);
  },[course]);

  const getTeachers = async () => {
    const q = query(collection(db, "users"), where("type", "==", "teacher"));
    const querySnapshot = await getDocs(q);
    setTeachers([]);
    querySnapshot.forEach((doc) => {
      setTeachers(last => [...last, doc]);
    });
  }
  const getTypes = async () => {
    const querySnapshot = await getDocs(collection(db, "types"));
    setTypes([]);
    querySnapshot.forEach((doc) => {
      setTypes(last => [...last, doc]);
    });
  }
  useEffect(() => {
    setShow(false)
    getLanguages();
    getLevels();
    getTeachers();
    getTypes();
    getCourses();
  }, [db]);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value
    })
  }
  const getCourses = async () => {
    const q = query(collection(db, "courses"), where('status', 'in', ['created', 'ready'])); //OR
    const querySnapshot = await getDocs(q);
    setCourses([]);
    querySnapshot.forEach((doc) => {
      setCourses(last => [...last, doc]);
    });
  }
  const action = () => {
    const idIpt = document.getElementById('id');
    if (idIpt.value === "") {
      addCourse();
    } else {
      updateCourse(idIpt.value);
    }
  }
  const addCourse = async () => {
    await addDoc(collection(db, "courses"), {
      language: data.language,
      level: data.level,
      teacher: {
        id: tchr.id,
        name: tchr.name
      },
      type: data.type,
      price: data.price,
      duration: data.duration,
      dateStart: data.dateStart,
      dateEnd: data.dateEnd,
      status: "created",
      schedule: [
        {
          class: false,  //Domingo
          starts: "",
          ends: ""
        },
        {
          class: false,  //Lunes
          starts: "",
          ends: ""
        },
        {
          class: false,  //Martes
          starts: "",
          ends: ""
        },
        {
          class: false,  //Miercoles
          starts: "",
          ends: ""
        },
        {
          class: false,  //Jueves
          starts: "",
          ends: ""
        },
        {
          class: false,  //Viernes
          starts: "",
          ends: ""
        },
        {
          class: false,  //Sábado
          starts: "",
          ends: ""
        },
        ],
      link: '',
      students:[]
    }).then(() => {
      clear();
      getCourses();
    });
  }
  const updateCourse = async (key) => {
    const courseRef = doc(db, "courses", key);
    await updateDoc(courseRef, {
      language: document.getElementById('language').value,
      level: document.getElementById('level').value,
      teacher: {
        id: tchr.id,
        name: tchr.name
      },
      type: document.getElementById('type').value,
      price: document.getElementById('price').value,
      duration: document.getElementById('duration').value,
      dateStart: document.getElementById('dateStart').value,
      dateEnd: document.getElementById('dateEnd').value
    }).then(() => {
      clear();
      getCourses();
    });
  }
  const updateStatusCourse = async (key, current) => {
    const courseRef = doc(db, "courses", key);
    await updateDoc(courseRef, {
      status: current === "created" ? "ready" : "created"
    }).then(() => {
      getCourses();
    });
  }
  const deleteCourse = async (key) => {
    await deleteDoc(doc(db, "courses", key)).then(() => {
      getCourses();
    })
  }
  const editCourse = async (key) => {
    const docRef = doc(db, "courses", key);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      document.getElementById('id').value = docSnap.id;
      document.getElementById('language').value = docSnap.data().language;
      document.getElementById('level').value = docSnap.data().level;
      document.getElementById('type').value = docSnap.data().type;
      document.getElementById('teacher').value = docSnap.data().teacher.id;
      document.getElementById('price').value = docSnap.data().price;
      document.getElementById('duration').value = docSnap.data().duration;
      document.getElementById('dateStart').value = docSnap.data().dateStart;
      document.getElementById('dateEnd').value = docSnap.data().dateEnd;
      setTchr({
        id: docSnap.data().teacher.id,
        name: docSnap.data().teacher.name
      })
    }
  }
  const clear = () => {
    document.getElementById('active-form').reset();
  }
  const selectedTeacher = () => {
    let teacher = document.getElementById('teacher');
    //tengo que llamar a una funcion que me devuelva el el name
    let x = teachers.findIndex(t => t.id === teacher.value)
    setTchr({
      id: teacher.value,
      name: teachers[x].data().name
    })
  }
  const view=(id,data)=>{
    setCourse({
      id:id,
      data:data
    })
  }
  const addStd=async(idc)=>{
    let idstd=document.getElementById('std-id').value;
    let namestd=document.getElementById('std-name').value;
    let emailstd=document.getElementById('std-email').value;
    const courseRef = doc(db, "courses", idc);
    await updateDoc(courseRef, {
        students: arrayUnion({
            id:idstd,
            name:namestd,
            email:emailstd
        })
    }).then(()=>{
      document.getElementById('addstd').reset();
    });
  }
  return (
    <div className='admin-courses-active-container bg-app'>
      <div className='container-md pt-3'>
        <h2>{t('general.active')}</h2>
        <form id='active-form' onSubmit={(e) => e.preventDefault()}>
          <div className='row'>
            <div className='col my-2'>
              <input type='text' placeholder='id' name='id' className='form-control' readOnly hidden id='id' />
            </div>
          </div>
          <div className='row '>
            <div className='col-md-6 my-2'>
              <select className='form-select' name='language' id='language' onChange={handleChange} required>
                <option defaultValue>{t('general.language')}</option>{
                  languages.map(language =>
                    <option key={language.id} value={language.data().language}>
                      {language.data().language}
                    </option>)
                }
              </select>
            </div>
            <div className='col-md-6 my-2'>
              <select className='form-select' name='level' id='level' onChange={handleChange}>
                <option defaultValue>{t('general.level')}</option>
                {
                  levels.map(level =>
                    <option key={level.id} value={level.data().level}>
                      {level.data().level}
                    </option>
                  )
                }
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 my-2'>
              <select className='form-select' name='teacher' id='teacher' onChange={() => selectedTeacher()}>
                <option defaultValue >{t('general.teacher')}</option>
                {
                  teachers.map(teacher =>
                    <option key={teacher.id} value={teacher.id}>
                      {teacher.data().name}
                    </option>)
                }
              </select>
            </div>
            <div className='col-md-6 my-2'>
              <select className='form-select' name='type' id='type' onChange={handleChange}>
                <option defaultValue>{t('general.type')}</option>
                {
                  types.map(type =>
                    <option key={type.id} value={type.data().type}>
                      {type.data().type}
                    </option>)//1701
                }
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 my-2'>
              <input type='text' placeholder={t('general.price')} name='price' id='price' className='form-control' onChange={handleChange} />
            </div>
            <div className='col-md-6 my-2'>
              <input type='text' placeholder={t('general.duration')} name='duration' id='duration' className='form-control' onChange={handleChange} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 my-1'>
              <label htmlFor='dateStart'>{t('general.dateStart')}:</label>
              <input type='date' className='form-control' id='dateStart' name='dateStart' onChange={handleChange} />
            </div>
            <div className='col-md-6 my-1'>
              <label htmlFor='dateEnd'>{t('general.dateEnd')}:</label>
              <input type='date' className='form-control' id='dateEnd' name='dateEnd' onChange={handleChange} />
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-secondary my-2 mx-1' onClick={clear}>{t('general.clear')} <FaEraser /></button>
            <button className='btn btn-primary my-2 mx-1' onClick={action}>{t('general.save')}<FaSave /></button>
          </div>
        </form>
      </div>
      <div className='table-responsive container-md mt-4'>
        <table className='table table-hover align-middle table-md'>
          <thead>
            <tr>
              <th scope='col'>{t('general.language')}</th>
              <th scope='col'>{t('general.level')}</th>
              <th scope='col'>{t('general.teacher')}</th>
              <th scope='col'>{t('general.type')}</th>
              <th scope='col'>{t('general.dateStart')}</th>
              <th scope='col'>{t('general.dateEnd')}</th>
              <th scope='col'>{t('general.duration')}</th>
              <th scope='col'>{t('general.price')}</th>
              <th scope='col'>{t('general.status')}</th>
              <th scope='col'>{t('general.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              courses.map(course =>
                <tr key={course.id}>
                  <td>{course.data().language}</td>
                  <td>{course.data().level}</td>
                  <td>{course.data().teacher.name}</td>
                  <td>{course.data().type}</td>
                  <td>{course.data().dateStart}</td>
                  <td>{course.data().dateEnd}</td>
                  <td>{course.data().duration} hrs</td>
                  <td>${course.data().price}</td>
                  <td>{course.data().status}</td>
                  <td>
                    <button className='btn btn-primary my-1 mx-1' onClick={()=>view(course.id,course.data())}><FaEye/></button>
                    <button className='btn btn-success my-1 mx-1' onClick={() => updateStatusCourse(course.id, course.data().status)}><FaRedo /></button>
                    <button className='btn btn-warning my-1 mx-1' onClick={() => editCourse(course.id)}><FaEdit /></button>
                    <button className='btn btn-danger my-1 mx-1' onClick={() => deleteCourse(course.id)} ><FaTrashAlt /></button>
                  </td>
                </tr>)
            }
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{course.data.language}{course.data.level}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Students</h4>
          <div>
            <form id='addstd' onSubmit={(e)=>e.preventDefault()}>
            <div>
              <input type='text' placeholder='id' className='form-control my-1' id='std-id'/>
            </div>
            <div>
            <input type='text' placeholder='name' className='form-control my-1' id='std-name' />
            </div>
            <div>
            <input type='email' placeholder='email' className='form-control my-1' id='std-email' />
            </div>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-primary my-1' onClick={()=>addStd(course.id)}>Add</button>
            </div>
            </form>
          </div>
          <table className='table table-md table-hover'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
              </tr>
            </thead>
            <tbody>
              {
                course.data.students.map(std=>
                  <tr key={std.id}>
                    <td>{std.name}</td>
                    <td>{std.email}</td>
                  </tr>)
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default React.memo(AdminCoursesActive)