import React from 'react';
import Retrato1 from '../static/images/Alumnos/RETRATO1.png';
import Retrato2 from '../static/images/Alumnos/RETRATO6.png';
import Retrato3 from '../static/images/Alumnos/RETRATO7.png';
import Retrato4 from '../static/images/Alumnos/RETRATO8.png';
import Retrato5 from '../static/images/Alumnos/retratoajustes.png';
import { useTranslation } from 'react-i18next';


const Comments = () => {
    const {t}=useTranslation();
  return (
    <div className='comments-container bg-app'>
        <div className='comments-title-container'>
            <h2>{t("reviews.title")}</h2>
        </div>
        <div className='slider-container'>
            <div className='slider'>
                    <div className='comment'>
                        <div className='img-comment' style={{backgroundImage:`url(${Retrato1})`}}>
                            <h3>{t('reviews.nebi.name')}</h3>
                        </div>
                        <div className='msg-comment bg-green'>
                            <p>{t('reviews.nebi.review')}</p>
                        </div>
                    </div>
                    <div className='comment'>
                        <div className='img-comment' style={{backgroundImage:`url(${Retrato2})`}}>
                            <h3>{t('reviews.pawel.name')}</h3>
                        </div>
                        <div className='msg-comment bg-red white'>   
                            <p>{t('reviews.pawel.review')}</p>
                        </div>
                    </div>
                    <div className='comment'>
                        <div className='img-comment' style={{backgroundImage:`url(${Retrato3})`}}>
                            <h3>{t('reviews.jeremiah.name')}</h3>
                        </div>
                        <div className='msg-comment bg-blue'>
                            <p>{t('reviews.jeremiah.review')}</p>
                        </div>
                    </div>
                    <div className='comment'>
                        <div className='img-comment' style={{backgroundImage:`url(${Retrato4})`}}>
                            <h3>{t('reviews.alejandra.name')}</h3>
                        </div>
                        <div className='msg-comment bg-yellow'>
                            <p>{t('reviews.alejandra.review')}</p>
                        </div>
                    </div>
                    <div className='comment'>
                        <div className='img-comment' style={{backgroundImage:`url(${Retrato5})`}}>
                            <h3>{t('reviews.sarah.name')}</h3>
                        </div>
                        <div className='msg-comment bg-amethyst'>
                            <p>{t('reviews.sarah.review')}</p>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Comments