import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminCoursesActive from '../../components/admin/AdminCoursesActive';

const AdminCActivePage = () => {
  return (
    <Fragment>
        <AdminNavBar/>
        <AdminCoursesActive/>
    </Fragment>
  )
}

export default AdminCActivePage