import React, { Fragment } from 'react';
import TeacherFinished from '../../components/teacher/TeacherFinished';
import TeacherNavBar from '../../components/teacher/TeacherNavBar';

const TeacherFinishedPage = () => {
  return (
    <Fragment>
        <TeacherNavBar/>
        <TeacherFinished/>
    </Fragment>
  )
}

export default TeacherFinishedPage