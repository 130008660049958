import React, { useState ,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import ProgressBar from 'react-bootstrap/ProgressBar';

const SpanishAdvance = () => {
    const {score}=useParams();
    var c=0;
    const correct=['deslumbrados',
    'pasmosas',
    'desfiladeros',
    'serpentea',
    'labrado',
    'vertiente',
    'flanqueado',
    'vapor',
    'recodo',
    'exuberante',
    'yerguen',
    'horizonte',
    'evaporada',
    'abrasada',
    'penachos'];
    const [time, setTime] = useState(300);
    const [progress, setProgress] = useState((time) * 100 / 300);
    useEffect(() => {
          if (time > 0) {
            setTimeout(() => { setTime(time - 1) }, 1000)
          }else{
            f()
          }
      
      setProgress((time) * 100 / 300)
    }, [time]);

    const f=()=>{
        let x0=document.getElementById('1');
        if(x0.value===correct[0]){
          c=c+1;
        }
        let x1=document.getElementById('2');
        if(x1.value===correct[1]){
          c=c+1;
        }
        let x2=document.getElementById('3');
        if(x2.value===correct[2]){
          c=c+1;
        }
        let x3=document.getElementById('4');
        if(x3.value===correct[3]){
          c=c+1;
        }
        let x4=document.getElementById('5');
        if(x4.value===correct[4]){
          c=c+1;
        }
        let x5=document.getElementById('6');
        if(x5.value===correct[5]){
          c=c+1;
        }
        let x6=document.getElementById('7');
        if(x6.value===correct[6]){
          c=c+1;
        }
        let x7=document.getElementById('8');
        if(x7.value===correct[7]){
          c=c+1;
        }
        let x8=document.getElementById('9');
        if(x8.value===correct[8]){
          c=c+1;
        }
        let x9=document.getElementById('10');
        if(x9.value===correct[9]){
          c=c+1;
        }
        let x10=document.getElementById('11');
        if(x10.value===correct[10]){
          c=c+1;
        }
        let x11=document.getElementById('12');
        if(x11.value===correct[11]){
          c=c+1;
        }
        let x12=document.getElementById('13');
        if(x12.value===correct[12]){
          c=c+1;
        }
        let x13=document.getElementById('14');
        if(x13.value===correct[13]){
          c=c+1;
        }
        let x14=document.getElementById('15');
        if(x14.value===correct[14]){
          c=c+1;
        }
        var total=c+Number(score);
        if(total<9){
          alert('Tu nivel es B1');
          window.location.href='/quiz-selection';
        }else if(total>=9 && total <18){
          alert('Tu nivel es B2+');
          window.location.href='/quiz-selection';
        }else if(total>=18 && total<32){
          alert('Tu nivel es C1+');
          window.location.href='/quiz-selection';
        }else if(total>=32){
          alert('Tu nivel es C2');
          window.location.href='/quiz-selection';
        }
      
    }
  return (
    <div className='bg-app std-height'>
        <p className='display-5'>Completa con la palabra correcta</p>
        <div className='mx-2 my-2 px-2 py-2'>
            No existe en el mundo región alguna que ofrezca a los (1) <input type='text' id='1' className='inpts'/> (deslumbrados/cerrados) ojos de los viajeros más deliciosas perspectivas que México; sobre todo la de las Cumbres es sin disputa una de las más (2) <input type='text' id='2' className='inpts'/> (pasmosas/espantosas) y seductivamente variadas.
						Las Cumbres forman una cadena de (3) <input type='text' id='3' className='inpts'/>  (desfiladeros/abismos) a la salida de las montañas, al través de las cuales y describiendo infinitas sinuosidades (4) <input type='text' id='4' className='inpts'/> (sabe/serpentea) el camino que conduce a Puebla de los Ángeles, así apellidada por haber los ángeles, según la tradición, (5) <input type='text' id='5' className='inpts'/> (arado/labrado) la catedral de la misma. El camino a que nos referimos, construido por los españoles, desciende por la (6) <input type='text' id='6' className='inpts'/> (vertiente/ladera) de las montañas formando ángulos sumamente atrevidos, y está (7) <input type='text' id='7' className='inpts'/>  (flanqueado/viendo) a derecha y a izquierda por una no interrumpida serie de empinadas aristas anegadas en azulado (8)<input type='text' id='8' className='inpts'/>  (aire/vapor). A cada (9) <input type='text' id='9' className='inpts'/> (recuerdo/recodo) de dicho camino, suspendido, por decirlo así, sobre precipicios cubiertos de (10)<input type='text' id='10'className='inpts'/> (exagerado/exuberante) vegetación, cambia la perspectiva y se hace cada vez más pintoresca; las cimas de las montañas no se elevan una tras otra, sino que van siendo gradualmente más bajas, mientras las que quedan a la espalda se (11) <input type='text' id='11' className='inpts'/> (yerguen/emergen) perpendicularmente.
						Poco más o menos a las cuatro de la tarde del 2 de julio de 18..., en el instante en que el sol, ya bajo en el (12) <input type='text' id='12' className='inpts'/> (horizonte/oriente), no difundía sino rayos oblicuos sobre la tierra, (13) <input type='text' id='13'/> (calcinada/evaporada) por el calor del mediodía, y en que la brisa al levantarse empezaba a refrescar la (14) <input type='text' id='14'className='inpts'/> (abrasada/abrumadora) atmósfera, dos viajeros, perfectamente montados, salieron de un frondoso bosque de yucas, bananos y bambúes de purpúreos (15) <input type='text' id='15' className='inpts'/> (penachos/sombreros) y se internaron en una polvorosa, larga y escalonada senda que afluía a un valle cruzado por límpido arroyo que se deslizaba al través de la hierba y conservaba fresco el ambiente.
        </div>
        <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
        <button className='btn btn-primary btn-start' onClick={f}>Finalizar</button>
    </div>
  )
}

export default SpanishAdvance