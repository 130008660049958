import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase'
import { doc, getDoc } from "firebase/firestore";
import Cookies from "js-cookie";
import axios from 'axios';


const Payment = () => {
    const {i18n}=useTranslation();
    const { id } = useParams()
    const token=process.env.REACT_APP_API_URL;
    const [curso, setCurso] = useState({
        language: "",
        level: "",
        teacher: {
            id: "",
            name: ""
        },
        type: "",
        price: "",
        duration: "",
        dateStart: "",
        dateEnd: ""
    })
    const getCourseData = async () => {
        const docRef = doc(db, "courses", id);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
            setCurso(docSnap.data())
        }
    }
    const setData = () => {
        console.log(Cookies.get("email"))
        if (Cookies.get("name")) {
            document.getElementById('name').value = Cookies.get("name")
            document.getElementById('email').value = Cookies.get("email")
        }
    }

    const verify=()=>{
        if(Cookies.get('name')===undefined){
            if(i18n.language==='en'){
                if(window.confirm('You need to login to continue')){
                    let x='/payment/'+id;
                    Cookies.set('pending',x)
                    window.location.href='/login'
                }
            }else{
                if(window.confirm('Tienes que iniciar sesión para continuar con la inscripción.')){
                    let x='/payment/'+id;
                    Cookies.set('pending',x)
                    window.location.href='/login'
                }
            }
        }
        
    }

    useEffect(() => {
        
        verify()
        getCourseData()
        setData()
        return () => {
        }
    }, [])

    const { t } = useTranslation();
    const lngs = {
        es: { nativeName: 'Español' },
        en: { nativeName: 'English' }
    };


    const pay = async () => {
        const uri = 'https://api.mercadopago.com/checkout/preferences?access_token=' + token;
        const headers = {
            'Content-Type': 'application/json'
        }
        let name = document.getElementById('name').value;
        let email = document.getElementById('email').value;
        let title = curso.language+" " + curso.level + " " + curso.type + " " + curso.duration + "hrs";
        let str=curso.price;
        str =  str.slice(0, str.length - 3);
        let cadenafiltered=str.replace(',','');
        console.log(cadenafiltered)
        var nprice=Math.round(cadenafiltered)
        const data = {
            items: [
                {
                    id: id,
                    title: title,
                    description: title,
                    category_id: "Trainings, Conferences, Workshops",
                    currency_id: 'MXN',
                    quantity: 1,
                    unit_price: nprice
                }
            ],
            payer: {
                name: name,
                email: email
            },
            back_urls: {
                success: 'www.skazkistudio.com/pay/success/' + id,
                failure: 'www.skazkistudio.com/courses'
            }
        };
        const respuesta = await axios.post(uri, data, {
            headers: headers
        });
        window.location.href = respuesta.data.init_point;


    }
    return (
        <div className='container-fluid bg-app std-height pt-2'>
            <div className='d-flex justify-content-between py-3 px-3'>
                <h3>{t('payment.title')}</h3>
                <DropdownButton id="dropdown-basic-button" title="Idioma-Language">
                    {Object.keys(lngs).map((lng) => (
                        <li key={lng} ><button style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                            {lngs[lng].nativeName}
                        </button></li>
                    ))}
                </DropdownButton>
            </div>
            <div className='payment-resumen my-3 px-5'>
                <h4>{t('payment.resume')} </h4>
                <table className='table table-sm table-hover table-borderless aling-middle'>
                    <tbody>
                        <tr>
                            <td>{t('payment.course')}:</td>
                            <td>{curso.language}</td>
                        </tr>
                        <tr>
                            <td>{t('general.level')}:</td>
                            <td>{curso.level}</td>
                        </tr>
                        <tr>
                            <td>{t('general.type')}:</td>
                            <td>{curso.type}</td>
                        </tr>
                        <tr>
                            <td>{t('general.duration')}:</td>
                            <td>{curso.duration} hrs</td>
                        </tr>
                        <tr>
                            <td>{t('general.dateStart')} :</td>
                            <td>{curso.dateStart}</td>
                        </tr>
                        <tr>
                            <td>{t('general.dateEnd')}:</td>
                            <td>{curso.dateEnd}</td>
                        </tr>
                    </tbody>
                </table>
                <form id='payment-form' onSubmit={(e)=>e.preventDefault()}>
                    <div className='mt-5'>
                        <h4>{t('payment.contact')}</h4>
                        <div className='mt-3'>
                            <input type='text' className='form-control my-2' placeholder={t('payment.name')} id='name' />
                            <input type='text' className='form-control my-2' placeholder={t('login.email')} id='email' />
                        </div>
                    </div>
                    <div className='ps-4 pt-3'>
                        <div className='mt-4 w-100 d-flex justify-content-end'>
                            <button className='btn btn-lg btn-primary' onClick={pay}>{t('payment.pay')} ${curso.price}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default Payment