import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminLanguages from '../../components/admin/AdminLanguages';

const AdminLanguagesPage = () => {
  return (
    <Fragment>
        <AdminNavBar/>
        <AdminLanguages/>
    </Fragment>
  )
}

export default AdminLanguagesPage