import { getDocs, collection, query, where} from 'firebase/firestore';
import { doc, updateDoc } from "firebase/firestore";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaEye, FaStopwatch} from 'react-icons/fa';
import { db } from '../../firebase';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal({show,onHide,data}) {
  const {t,i18n}=useTranslation();
  const dias=['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sábado'];
  const days=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const [week,setWeek]=useState(days);
  useEffect(()=>{
    if(i18n.language==='es'){
      setWeek(dias)
    }else{
      setWeek(days)
    }
  },[i18n.language]);
  const update=async()=>{
    let sunday=document.getElementById('Sunday').checked;
    let sundayStarts=document.getElementById('SundayStarts').value;
    let sundayEnds=document.getElementById('SundayEnds').value;

    let monday=document.getElementById('Monday').checked;
    let mondayStarts=document.getElementById('MondayStarts').value;
    let mondayEnds=document.getElementById('MondayEnds').value;

    let tuesday=document.getElementById('Tuesday').checked;
    let tuesdayStarts=document.getElementById('TuesdayStarts').value;
    let tuesdayEnds=document.getElementById('TuesdayEnds').value;

    let wednesday=document.getElementById('Wednesday').checked;
    let wednesdayStarts=document.getElementById('WednesdayStarts').value;
    let wednesdayEnds=document.getElementById('WednesdayEnds').value;

    let thursday=document.getElementById('Thursday').checked;
    let thursdayStarts=document.getElementById('ThursdayStarts').value;
    let thursdayEnds=document.getElementById('ThursdayEnds').value;

    let friday=document.getElementById('Friday').checked;
    let fridayStarts=document.getElementById('FridayStarts').value;
    let fridayEnds=document.getElementById('FridayEnds').value;

    let saturday=document.getElementById('Saturday').checked;
    let saturdayStarts=document.getElementById('SaturdayStarts').value;
    let saturdayEnds=document.getElementById('SaturdayEnds').value;

    var schedule=[{
      class:sunday,
      starts:sundayStarts,
      ends:sundayEnds
    },{
      class:monday,
      starts:mondayStarts,
      ends:mondayEnds
    },{
      class:tuesday,
      starts:tuesdayStarts,
      ends:tuesdayEnds
    },{
      class:wednesday,
      starts:wednesdayStarts,
      ends:wednesdayEnds
    },{
      class:thursday,
      starts:thursdayStarts,
      ends:thursdayEnds
    },{
      class:friday,
      starts:fridayStarts,
      ends:fridayEnds
    },{
      class:saturday,
      starts:saturdayStarts,
      ends:saturdayEnds
    }]
    const docRef=doc(db,"courses",data.id);
    await updateDoc(docRef,{
      schedule:schedule
    }).then(()=>{
      onHide();
    });
    
  }


  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {data.data.language}-{data.data.level}-{data.data.type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className='table table-sm table-hover align-middle'>

        </table>
        <table className='table table-sm table-hover align-middle'>
          <thead>
            <tr>
              <th scope='col'>{t('general.day')}</th>
              <th scope='col'>{t('general.class?')}</th>
              <th scope='col'>Starts</th>
              <th scope='col'>Ends</th>
            </tr>
          </thead>
          <tbody>
          {
            week.map((day,index)=> 
            <tr key={index}>
              <td> {week[index]} </td>
              <td><input type='checkbox' defaultChecked={data.data.schedule[index].class} id={days[index]}/>  </td>
              <td><input type='time' defaultValue={data.data.schedule[index].starts} className='form-control' id={days[index]+'Starts'} /> </td>
              <td><input type='time' defaultValue={data.data.schedule[index].ends} className='form-control' id={days[index]+'Ends'}/> </td>
            </tr>)
          }
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('general.close')}</Button>
        <button className='btn btn-primary mx-1' onClick={update} >{t('general.save')}</button>
      </Modal.Footer>
    </Modal>
  );
}


const TeacherActive = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose=()=>setShow(false);
  const [data,setData]=useState({
    id:'',
    data:{
      dateEnd:'',
      dateStart:'',
      duration:'',
      schedule:[
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        },
        {
          class:false,
          starts:'',
          ends:''
        }
      ],
      level:'',
      price:'',
      teacher:'',
      type:'',
      students:[]
    }});
  const getCourses = async () => {
    const q = query(collection(db, "courses"), where('status', 'in', ['created', 'ready', 'started'])); //OR
    const querySnapshot = await getDocs(q);
    setCourses([]);
    querySnapshot.forEach((doc) => {
      setCourses(last => [...last, doc]);
    });
  }
  useEffect(() => {
    getCourses();
  }, []);

  useEffect(()=>{
    if(data.action==='time'){
      setModalShow(true)
    }else if(data.action==='student'){
      setShow(true)
    }
  },[data])

  const startCourse=async(key)=>{
    let text = "¿Deseas iniciar el curso?";
    if (window.confirm(text) === true) {
      const docRef=doc(db,"courses",key);
      await updateDoc(docRef,{
        status:'started'
      }).then(()=>{
        getCourses();
      });
    }
  }
  const finishCourse=async(key)=>{
    let text = "¿Deseas finalizar el curso?";
    if (window.confirm(text) === true) {
      const docRef=doc(db,"courses",key);
      await updateDoc(docRef,{
        status:'finished'
      }).then(()=>{
        getCourses();
      });
    }
  }


  return (
    <div className='bg-app std-height pt-5'>
      <div className='container-md pt-4'>
        <h3>Active</h3>
        <div className='table-responsive'>
          <table className='table table-md table-hover align-middle'>
            <thead>
              <tr>
                <th scope='col'>{t('general.language')}</th>
                <th scope='col'>{t('general.level')}</th>
                <th scope='col'>{t('general.type')}</th>
                <th scope='col'>{t('general.dateStart')}</th>
                <th scope='col'>{t('general.dateEnd')}</th>
                <th scope='col'>{t('general.duration')}</th>
                <th scope='col'>{t('general.status')}</th>
                <th scope='col'>{t('general.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {
                courses.filter(c=> c.data().teacher.name===Cookies.get('name')).map(course =>
                  <tr key={course.id}>
                    <td>{course.data().language}</td>
                    <td>{course.data().level}</td>
                    <td>{course.data().type}</td>
                    <td>{course.data().dateStart}</td>
                    <td>{course.data().dateEnd}</td>
                    <td>{course.data().duration} hrs</td>
                    <td>{course.data().status}</td>
                    <td>
                      <button className='btn btn-secondary my-1 mx-1' onClick={()=>setData({id:course.id,data:course.data(),action:'time'})}><FaStopwatch/></button>
                      <button className='btn btn-primary my-1 mx-1'  onClick={()=>setData({id:course.id,data:course.data(),action:'student'})}><FaEye /></button>
                      <button className='btn btn-success my-1 mx-1' onClick={()=>{
                        if(course.data().status==='ready'){
                          startCourse(course.id)
                        }else if(course.data().status==='started'){
                          finishCourse(course.id)
                        }
                        }}><FaArrowRight /></button>
                    </td>
                  </tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{data.data.language}{data.data.level}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Students</h4>
          <div>
            <form id='addstd' onSubmit={(e)=>e.preventDefault()}>
            <div>
              <input type='text' placeholder='id' className='form-control my-1' id='std-id'/>
            </div>
            <div>
            <input type='text' placeholder='name' className='form-control my-1' id='std-name' />
            </div>
            <div>
            <input type='email' placeholder='email' className='form-control my-1' id='std-email' />
            </div>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-primary my-1'>Add</button>
            </div>
            </form>
          </div>
          <table className='table table-md table-hover'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
              </tr>
            </thead>
            <tbody>
              {
                data.data.students.map(std=>
                  <tr key={std.id}>
                    <td>{std.name}</td>
                    <td>{std.email}</td>
                  </tr>)
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TeacherActive