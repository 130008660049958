import React,{useState,useEffect} from 'react';
import { FaEdit, FaTrashAlt, FaSave, FaEraser } from "react-icons/fa";
import { db } from '../../firebase';
import { collection, deleteDoc, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

const AdminLanguages = () => {
    
    const {t}=useTranslation();
    const [languages, setLanguages] = useState([]);
    const getLanguages = async () => {
        const querySnapshot = await getDocs(collection(db,"languages"));
        setLanguages([]);
        querySnapshot.forEach((doc) => {
            setLanguages(last => [...last, doc]);
        });
    }
    useEffect(() => {
        getLanguages()
    }, [db]);

    const action = async () => {
        const id = document.getElementById('id');
        const languageIpt = document.getElementById('language');
        if (id.value === "") {
            //add
            await addDoc(collection(db, "languages"), {
                language: languageIpt.value
            }).then(() => {
                clear();
                getLanguages();
            });
        } else {
            //update
            const languageRef = doc(db, "languages", id.value);
            await updateDoc(languageRef, {
                language: languageIpt.value
            }).then(() => {
                clear();
                getLanguages();
            });
        }
    }

    const edit = (key, language) => {
        document.getElementById('id').value = key;
        document.getElementById('language').value = language;
    }
    const clear = () => {
        document.getElementById('language-form').reset();
    }
    const erase = async (key) => {
        await deleteDoc(doc(db, 'languages', key)).then(() => {
            getLanguages();
        })
    }

    return (
        <div className='admin-languages-container'>
            <div className='container-md'>
                <div className='container-md'>
                    <div className='d-flex justify-content-center my-3'>
                        <h2>{t('general.languages')}</h2>
                    </div>
                    <form id='language-form' onSubmit={(e)=>e.preventDefault()} >
                    <div className='row my-2 mx-2'>
                        <label htmlFor='id' className='col-md-4'>Id:</label>
                        <div className='col-md-8'>
                            <input type='text' className='form-control' name='id' id='id' readOnly/>
                        </div>
                    </div>
                    <div className='row my-2 mx-2'>
                        <label htmlFor='language' className='col-md-4'>{t('general.language')}:</label>
                        <div className='col-md-8'>
                            <input type='text' className='form-control' name='language' id='language' />
                        </div>
                    </div>
                    <div className='my-2 mx-2 d-flex justify-content-end'>
                        <button className='btn btn-secondary my-2 mx-1' onClick={clear}>{t('general.clear')} <FaEraser /> </button>
                        <button className='btn btn-primary my-2 mx-1' onClick={action}>{t('general.save')} <FaSave /> </button>
                    </div>
                    </form>
                </div>
                <div className='table-responsive'>
                    <table className='table table-md table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'>{t('general.language')}</th>
                                <th scope='col'>{t('general.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                languages.map(language=>
                                    <tr key={language.id}>
                                        <td>{language.data().language}</td>
                                        <td>
                                            <button className='btn btn-warning mx-1 my-1' onClick={()=>edit(language.id,language.data().language)}><FaEdit/></button>
                                            <button className='btn btn-danger mx-1 my-1' onClick={()=>erase(language.id)}><FaTrashAlt/></button> 
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default React.memo(AdminLanguages)