import React, {  useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const EnglishBeginner = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question: "____'s your name? Thomas",
            answers: ['How', 'Who ', 'What', 'Where'],
            key: 2,
            type: 0,
        },
        {
            question: 'This is Lucy and her brother, Dan. ____ my friends.',
            answers: ["We're", "I'm", "You're", "They're"],
            key: 3,
            type: 0,
        },
        {
            question: "____? I'm from Italy.",
            answers: [
                'Where are you from?',
                'Where you are from?',
                'Where from you are?',
                'From where you are?',
            ],
            key: 1,
            type: 0,
        },
        {
            question: "I'm from Milan. ____ is in Italy.",
            answers: ['They', 'It', 'He', 'She'],
            key: 1,
            type: 0,
        },
        {
            question: 'Excuse me, how ____ your last name? R-I-L-E-Y',
            answers: ['spell', 'you spell', 'do you spell', 'spell you'],
            key: 2,
            type: 0,
        },
        {
            question: 'Oh, ____ are my keys!',
            answers: ['This', 'These', 'That', 'It'],
            key: 1,
            type: 0,
        },
        {
            question: "I'd like ____ omelette, please.",
            answers: ['a', '-', 'an', 'two'],
            key: 2,
            type: 0,
        },
        {
            question: 'And here is your ____.',
            answers: ['desk', 'desks', 'a desk', 'an desk'],
            key: 0,
            type: 0,
        },
        {
            question:
                "My name's Pete and this is Sylvia. ____ doctors from France.",
            answers: ["I'm", "We're", "She's", "They're"],
            key: 1,
            type: 0,
        },
        {
            question: "Sorry, ____ Paul. My name's Eric.",
            answers: ["I isn't", 'I is not', "I aren't", "I'm not"],
            key: 3,
            type: 0,
        },
        {
            question: "____? No, he isn't.",
            answers: [
                'Are they teachers?',
                'Are you from Italy?',
                'Is Mr Banning a teacher?',
                'Is this your phone?',
            ],
            key: 2,
            type: 0,
        },
        {
            question: "____ is the school? It's 50 years old.",
            answers: [
                'How many years',
                'How much years',
                'What years',
                'How old',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'What is ____?',
            answers: ['job Mary', 'Mary job', "Mary's job", "job's Mary"],
            key: 2,
            type: 0,
        },
        {
            question: 'Your bag is next ____ the table.',
            answers: ['on', 'to', 'in', 'of'],
            key: 1,
            type: 0,
        },
        {
            question: '____ are the keys? On the table.',
            answers: ['What', 'When', 'Where', 'Who'],
            key: 2,
            type: 0,
        },
        {
            question: 'I go to work ____ train.',
            answers: ['with', 'by', 'for', 'in'],
            key: 2,
            type: 0,
        },
        {
            question: 'She ____ a dog.',
            answers: [
                'not have',
                "don't have",
                "don't has",
                "doesn't have",
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'Stephen ____ in our company.',
            answers: ['work', 'works', 'is work', 'working'],
            key: 1,
            type: 0,
        },
        {
            question: '____ they live in London?',
            answers: ['Are', 'Is', 'Do', 'Does'],
            key: 2,
            type: 0,
        },
        {
            question: '____ to the cinema.',
            answers: [
                'We not often go',
                "We don't go often",
                "We don't often go",
                "Often we don't go",
            ],
            key: 2,
            type: 0,
        },
        {
            question: 'When do you play tennis? ____ Mondays.',
            answers: ['On', 'In', 'At', 'By'],
            key: 0,
            type: 0,
        },
        {
            question: 'What time ____ work?',
            answers: [
                'starts he',
                'do he starts',
                'does he starts',
                'does he start',
            ],
            key: 2,
            type: 0,
        },
        {
            question: '____ two airports in the city.',
            answers: ['It is', 'There is', 'There are', 'This is'],
            key: 2,
            type: 0,
        },
        {
            question: "There aren't ____ here.",
            answers: [
                'a restaurants',
                'any restaurants',
                'any restaurant',
                'a restaurant',
            ],
            key: 1,
            type: 0,
        },
        {
            question: "I'm afraid it's ____.",
            answers: [
                'a hotel expensive',
                'expensive hotel',
                'expensive a hotel',
                'an expensive hotel',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'They ____ popular TV programmes in the 1980s.',
            answers: ['are', 'were', 'was', 'is'],
            key: 1,
            type: 0,
        },
        {
            question: '____ at school last week?',
            answers: ['Do you were', 'Was you', 'Were you', 'You were'],
            key: 2,
            type: 0,
        },
        {
            question: "Brad Pitt is a popular actor but I don't like ____.",
            answers: ['him', 'his', 'her', 'them'],
            key: 0,
            type: 0,
        },
        {
            question: 'We ____ the film last week.',
            answers: ['see', 'saw', 'sees', 'were see'],
            key: 1,
            type: 0,
        },
        {
            question: 'He ____ tennis with me yesterday.',
            answers: [
                "doesn't played",
                "didn't played",
                'not played',
                "didn't play",
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'She was born ____ May 6th, 1979.',
            answers: ['in', 'at', 'on', 'from'],
            key: 2,
            type: 0,
        },
        {
            question: 'Where ____ last summer?',
            answers: [
                'you went',
                'did you went',
                'do you went',
                'did you go',
            ],
            key: 3,
            type: 0,
        },
        {
            question:
                'Were you at the shops at 5 p.m. yesterday?    No, I ____',
            answers: ["didn't", 'am not', "wasn't", "weren't"],
            key: 3,
            type: 0,
        },
        {
            question: "Excuse me, ____ is the T-shirt? It's ₤25.99.",
            answers: [
                'what expensive',
                'how much',
                'how many',
                'how price',
            ],
            key: 2,
            type: 0,
        },
        {
            question: "She's only four but she ____.",
            answers: ['can read', 'cans read', 'can reads', 'cans reads'],
            key: 0,
            type: 0,
        },
        {
            question: 'This party is boring. We ____ a good time.',
            answers: [
                "don't have",
                "aren't having",
                "don't having",
                "aren't have",
            ],
            key: 1,
            type: 0,
        },
        {
            question: 'Sorry, I ____ you at the moment.',
            answers: [
                "can't help",
                "don't can help",
                "can't helping",
                "can't helps",
            ],
            key: 0,
            type: 0,
        },
        {
            question: 'I ____ my computer very often.',
            answers: [
                'am not using',
                "don't use",
                "doesn't use",
                'am not use',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                "It's my mum's birthday next week. I ____ her a present.",
            answers: ['buy', 'buys', 'am going to buy', 'buying'],
            key: 2,
            type: 0,
        },
        {
            question: 'What ____ do after school today?',
            answers: ['are you going to', 'are you', 'do you', 'you'],
            key: 0,
            type: 0,
        },
        {
            question: "Gina is married to John. He's her ____",
            answers: ['uncle', 'husband', 'wife', 'parent'],
            key: 2,
            type: 1,
        },
        {
            question: "I love this watch! It's ____.",
            answers: ['cheap', 'small', 'beautiful', 'ugly'],
            key: 0,
            type: 1,
        },
        {
            question: "He doesn't have a car so he often uses public ____.",
            answers: ['taxi', 'transport', 'car', 'bus'],
            key: 1,
            type: 1,
        },
        {
            question: "I don't go to ____ on Sundays.",
            answers: ['job', 'office', 'factory', 'work'],
            key: 3,
            type: 1,
        },
        {
            question: 'Do you like Chinese ____?',
            answers: ['kitchen', 'meal', 'food', 'cook'],
            key: 2,
            type: 1,
        },
        {
            question: 'They hardly ____ visit us.',
            answers: ['ever', 'sometimes', 'never', 'usually'],
            key: 0,
            type: 1,
        },
        {
            question: "I'm Jeff Caine. Nice to ____ you, Mr Caine.",
            answers: ['speak', 'talk', 'meet', 'watch'],
            key: 2,
            type: 1,
        },
        {
            question: "Can I help you? Thanks, but I'm just ____.",
            answers: ['watching', 'looking', 'seeing', 'shopping'],
            key: 1,
            type: 1,
        },
        {
            question:
                "Mandy is over there. She's ____ a blue T-shirt and jeans.",
            answers: ['having', 'wearing', 'doing', 'walking'],
            key: 1,
            type: 1,
        },
    ]
    const types = ['Grammar', 'Vocabulary']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);
    
    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        } 
            if(answered.length===questions.length){
                let score = answered.filter(answer => answer === 1).length;
            if (score <= 35) {
                alert('Your level is beginner');
                window.location.href='/quiz-selection';
            } else if (score > 35 ) {
                alert('Your level es Elementary');
                window.location.href='/quiz-selection';
            }
            }
            
        
    }, [answered])
  return (
    <div className='quiz-container' >
                <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
                <p className='question' hidden={!start}>{questions[position].question}</p>
                <div className='answers' hidden={!start}>
                    {
                        questions[position].answers.map((answer, index) =>
                            <div key={index} className='answer' onClick={() => {
                                if (index === questions[position].key) {
                                    setAnswered(answered.concat(1))
                                } else {
                                    setAnswered(answered.concat(0))
                                }
                                setAux(1)
                            }}>
                                {answer}
                            </div>)
                    }
                </div>
                <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
                <div className='quiz-progress'>{position + 1}/{questions.length}</div>
                <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
                <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
                <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                    setAnswered(answered.concat(0))
                    setAux(1)
                }}>Next</button>
            </div>
  )
}

export default EnglishBeginner