import React, {  useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const ItalianIntermediate = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question:
                "La discoteca è vuota, come mai non c'è ancora ............ ?",
            answers: ['alcuno', 'alcuni', 'alcune', 'nessuno'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Stasera ho comprato un maglione di lana ed un paio di scarpe di Magli. Ho ............ circa trecentomila lire in tutto.',
            answers: ['dato', 'prestato', 'speso', 'preso'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Franco lavora ............ Enzo e così guadagna solo un milione al mese.',
            answers: ['di più di', 'come', 'meno che', 'meno di'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Arrivo ............ una settimana col volo che parte da Milano alle 15.30.',
            answers: ['fra', 'per', 'in', 'da'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Il tempo in Italia è indubbiamente ............ in Inghilterra.',
            answers: [
                'meglio di',
                'migliore che',
                'migliore di',
                'meglio che',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                'Ragazzi ............ zitti, per favore! Il concerto sta per cominciare!',
            answers: ['siate', 'siete', 'state', 'stiano'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Ieri Luisa e Mario ............ alzati prestissimo per prendere il primo treno per Bari',
            answers: ['si sono', 'ci siamo', 'hanno', 'sono'],
            key: 0,
            type: 0,
        },
        {
            question:
                "Ma dai, ............ un po' di pazienza Giorgio! Il treno porta soltanto dieci minuti di ritardo.",
            answers: ['avete', 'abbiate', 'abbia', 'abbi'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Claudia, a che ora ............ libera domani sera per una partita di tennis?',
            answers: ['sarò', 'sarete', 'saranno', 'sarai'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Quando Sarah aveva sedici anni ............ ogni anno in vacanza in Francia con la sua famiglia.',
            answers: ['va', 'andrà', 'andava', 'è andata'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Buongiorno signora, mi ............ dire per favore se ci sono camere libere per questa notte?',
            answers: ['potrebbe', 'potresti', 'potrebbero', 'puoi'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Paolo, vuoi raccontarci quello che hai visto al cinema ieri sera? Sì certo, ............ racconto volentieri.',
            answers: ['te lo', 'glielo', 've lo', 'me lo'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Le persone ............ sono andata a cena ieri sono molto simpatiche!',
            answers: ['con che', 'con cui', 'con chi', 'con la quale'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Michelangelo Buonnarroti nacque a Firenze nel 1475, ma ............ per molti anni a Roma.',
            answers: ['lavorava', 'ha lavorato', 'lavorò', 'lavora'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Giulia, che cosa ............ quando ti ho chiamato ieri sera?',
            answers: ['fai', 'stai facendo', 'fate', 'stavi facendo'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Non so ancora quando partirò, te lo farò sapere dopo che ............ il volo.',
            answers: [
                'sto prenotando',
                'avrò prenotato',
                'prenoterò',
                'prenotavo',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                'Quanto è maleducato quel ragazzo! ............ senza dire nè buongiorno, nè grazie!',
            answers: [
                "se n'è andato",
                'si va',
                'ci è andata',
                'ci andiamo',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'Ti è arrivata in tempo la lettera che ti ho ............ lunedì scorso?',
            answers: ['spedito', 'spedita', 'spediti', 'spedite'],
            key: 0,
            type: 0,
        },
        {
            question:
                "Hai consegnato alla segretaria tutti i documenti per l'iscrizione al corso d'inglese? Sì glieli ho ............ tutti.",
            answers: ['dato', 'dati', 'date', 'data'],
            key: 1,
            type: 0,
        },
        {
            question:
                '............ non sopporto affatto di Luisa è la sua intransingenza!.',
            answers: ['ciò che', 'quella che', 'quelli che', 'quale'],
            key: 0,
            type: 0,
        },
        {
            question:
                '............ appena seduto a tavola, quando mi hai telefonato.',
            answers: ['ero', 'mi ero', 'mi sono', 'avevo'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Vi va di prendere qualcosa al bar dopo ............ in piscina?',
            answers: ['che andavamo', 'essere andati', 'andando', 'andare'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Laura ............ per forza sposare di venerdi, 13 gennaio!',
            answers: ['si è voluta', 'ha voluto', 'si è voluto', 'voleva'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Credo che Paolo e Giuliano ............ stamattina per Parigi, ma non sono assolutamente sicura.',
            answers: ['parti', 'partiamo', 'partano', 'partono'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Bisogna che voi due ............ di meno e stiate più attente!',
            answers: ['parliate', 'parlino', 'parliamo', 'parlate'],
            key: 0,
            type: 0,
        },
    ]
    const types = ['']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);

    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            if (score <= 7) {
                alert('Tu nivel es A2+');
                window.location.href = '/quiz-selection';
            } else if (score > 7 && score<=15) {
                alert('Tu nivel es B1');
                window.location.href = '/quiz-selection';
            }else if(score>15){
                alert('Tu nivel es B1+');
                window.location.href = '/quiz-selection';
            }
        }


    }, [answered])
    return (
        <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
    )
}

export default ItalianIntermediate