import React, { Fragment } from 'react'
import NavigationBar from '../components/NavigationBar';
import EnglishLevels from '../components/quizzes/EnglishLevels';

const EnglishLevelsPage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <EnglishLevels/>
    </Fragment>
  )
}

export default EnglishLevelsPage