import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminTypes from '../../components/admin/AdminTypes';

const AdminTypesPage = () => {
  return (
    <Fragment>
        <AdminNavBar/>
        <AdminTypes/>
    </Fragment>
  )
}

export default AdminTypesPage