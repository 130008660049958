import React,{Fragment} from 'react';
import StudentNavBar from '../../components/student/StudentNavBar';
import Student from '../../components/student/Student';

const StudentPage = () => {
  return (
    <Fragment>
      <StudentNavBar/>
      <Student/>
    </Fragment>
  )
}

export default StudentPage