import React, { Fragment } from 'react';
import NavigationBar from '../components/NavigationBar';
import QuizSelection from '../components/QuizSelection';

const QuizSPage = () => {
  return (
    <Fragment>
        <NavigationBar/>
        <QuizSelection/>
    </Fragment>
  )
}

export default QuizSPage