import React from 'react'
import { NavLink } from 'react-router-dom'

const QuizSelection = () => {
  return (
    <div className='bg-app std-height mt-5 pt-5'>
      <div className='container-md'>
        <h2 className='text-center'>Select language quiz</h2>
        <div className='language-buttons-container'>
          <NavLink to='/english-levels' className='language-button'>English</NavLink>
          <NavLink to='/spanish-levels' className='language-button'>Spanish</NavLink>
          <NavLink to='/russian-levels' className='language-button'>Russian</NavLink>
          <NavLink to='/italian-levels' className='language-button'>Italian</NavLink>
        </div>
      </div>
    </div>
  )
}

export default QuizSelection