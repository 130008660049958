import React from 'react'

const Admin = () => {
  return (
    <div className='d-flex justify-content-center align-items-center  pt-5 std-height bg-app'>
      <p className='lead'>Bienvenido admin</p>
    </div>
  )
}

export default Admin