import './App.css';
import React from 'react';
import {BrowserRouter , Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import CoursesPage from './pages/CoursesPage';
import AboutUsPage from './pages/AboutUsPage';
import LoginPage from './pages/LoginPage';
import StudentPage from './pages/student/StudentPage';
import TeacherPage from './pages/teacher/TeacherPage';
import AdminPage from './pages/admin/AdminPage';
import StudentRegisterPage from './pages/student/StudentRegisterPage';
import TeacherRegisterPage from './pages/teacher/TeacherRegisterPage';
import AdminLanguagesPage from './pages/admin/AdminLanguagesPage';
import AdminTypesPage from './pages/admin/AdminTypesPage';
import AdminLevelsPage from './pages/admin/AdminLevelsPage';
import AdminTeachersPage from './pages/admin/AdminTeachersPage';
import AdminCActivePage from './pages/admin/AdminCActivePage';
import AdminCFinishedPage from './pages/admin/AdminCFinishedPage';
import TeacherActivePage from './pages/teacher/TeacherActivePage';
import StdAccountPage from './pages/student/StdAccountPage';
import TeacherProfilePage from './pages/TeacherProfilePage';
import TeacherFinishedPage from './pages/teacher/TeacherFinishedPage';
import StdActivePage from './pages/student/StdActivePage';
import StdFinishedPage from './pages/student/StdFinishedPage';
import QuizSPage from './pages/QuizSPage';
import SpanishLevelsPage from './quizzes/SpanishLevelsPage';
import SpanishBeginner from './components/quizzes/SpanishBeginner';
import SpanishIntermediate from './components/quizzes/SpanishIntermediate';
import SpanishUpperIntermediate from './components/quizzes/SpanishUpperIntermediate';
import SpanishAdvanced from './components/quizzes/SpanishAdvanced';
import SpanishAdvance from './components/quizzes/SpanishAdvance';
import EnglishLevelsPage from './quizzes/EnglishLevelsPage';
import EnglishBeginner from './components/quizzes/EnglishBeginner';
import EnglishIntermediate from './components/quizzes/EnglishIntermediate';
import EnglishAdvance from './components/quizzes/EnglishAdvance';
import ItalianLevelsPage from './quizzes/ItalianLevelsPage';
import ItalianBeginner from './components/quizzes/ItalianBeginner';
import ItalianIntermediate from './components/quizzes/ItalianIntermediate';
import ItalianAdvance from './components/quizzes/ItalianAdvance';
import RussianLevelsPage from './quizzes/RussianLevelsPage';
import RussianIntermediate from './components/quizzes/RussianIntermediate';
import RussianAdvance from './components/quizzes/RussianAdvance';
import RussianBeginner from './components/quizzes/RussianBeginner';
import Payment from './components/Payment';
import Success from './components/Success';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
       <ScrollToTop>
       <Routes>
       <Route path='/' element={<Home/>}/>
        <Route path='/courses/:id' element={<CoursesPage/>}/>
        <Route path='/aboutUs' element={<AboutUsPage/>}/>
        <Route path='/login' element={<LoginPage/>}/>
        <Route path='/student' element={<StudentPage/>}/>
        <Route path='/teacher' element={<TeacherPage/>}/>
        <Route path='/admin' element={<AdminPage/>}/>
        <Route path='/admin/languages' element={ <AdminLanguagesPage/> }/>
        <Route path='/admin/types' element={ <AdminTypesPage/> }/>
        <Route path='/admin/levels' element={ <AdminLevelsPage/> }/>
        <Route path='/admin/teachers' element={ <AdminTeachersPage/> }/>
        <Route path='/admin/active' element={ <AdminCActivePage/> }/>
        <Route path='/admin/finished' element={ <AdminCFinishedPage/> }/>
        <Route path='/teacher-profile/:id' element={<TeacherProfilePage/>}/>
        <Route path='/teacher-register' element={ <TeacherRegisterPage/> }/>
        <Route path='/teacher/active' element={<TeacherActivePage/>}/>
        <Route path='/teacher/finished' element={<TeacherFinishedPage/>}/>
        <Route path='/student-register' element={ <StudentRegisterPage/> }/>
        <Route path='/std-account' element={<StdAccountPage/>}/>
        <Route path='/student/active' element={<StdActivePage/>}/>
        <Route path='/student/finished' element={<StdFinishedPage/>}/>
        <Route path='/quiz-selection' element={<QuizSPage/>}/>
        <Route path='/spanish-levels' element={<SpanishLevelsPage/>}/>
        <Route path='/spanish-beginner' element={<SpanishBeginner/>}/>
        <Route path='/spanish-intermediate' element={<SpanishIntermediate/>}/>
        <Route path='/spanish-upperintermediate' element={<SpanishUpperIntermediate/>} />
        <Route path='/spanish-advance' element={<SpanishAdvanced/>} />
        <Route path='/spanish-advance/:score' element={<SpanishAdvance/>} />
        <Route path='/english-levels' element={<EnglishLevelsPage/>}/>
        <Route path='/english-beginner' element={<EnglishBeginner/>}/>
        <Route path='/english-intermediate' element={<EnglishIntermediate/>}/>
        <Route path='/english-upperintermediate' element={<EnglishAdvance/>}/>
        <Route path='/italian-levels' element={<ItalianLevelsPage/>}/>
        <Route path='/italian-beginner' element={<ItalianBeginner/>}/>
        <Route path='/italian-intermediate' element={<ItalianIntermediate/>}/>
        <Route path='/italian-advance' element={<ItalianAdvance/>}/>
        <Route path='/russian-levels' element={<RussianLevelsPage/>}/>
        <Route path='/russian-beginner' element={<RussianBeginner/>}/>
        <Route path='/russian-intermediate' element={<RussianIntermediate/>}/>
        <Route path='/russian-advance/:score' element={<RussianAdvance/>} />
        <Route path='/payment/:id' element={<Payment/>}/>
        <Route path='/pay/success/:id' element={<Success/>}/>
        </Routes>
       </ScrollToTop>
     
      </BrowserRouter>
    </div>
  );
}

export default App;
