import React, { useEffect, useState } from 'react';
import {db} from '../../firebase';
import { getDocs,collection ,query,where} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';



const AdminTeachers = () => {
    const {t}=useTranslation();
    const [teachers,setTeachers]=useState([]);
    const getTeachers = async () => {
        const q = query(collection(db, "users"), where("type", "==", "teacher"));
        const querySnapshot = await getDocs(q);
        setTeachers([]);
        querySnapshot.forEach((doc) => {
            setTeachers(last => [...last, doc]);
        });
    }
    useEffect(() => {
        getTeachers();
    }, [db]);
    return (
        <div className='admin-types-container'>
            <div className='container-md'>
                <div className='d-flex justify-content-center my-3'>
                    <h2>{t('general.teachers')}</h2>
                </div>
                <div className='table-responsive'>
                    <table className='table table-md table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'>{t('general.name')}</th>
                                <th scope='col'>{t('general.email')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teachers.map(teacher=>
                                    <tr key={teacher.id}>
                                        <td>
                                            {teacher.data().name}
                                        </td>
                                        <td>
                                            {teacher.data().email}
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default React.memo(AdminTeachers)