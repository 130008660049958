import React,{Fragment} from 'react';
import NavigationBar from '../components/NavigationBar';
import Courses from '../components/Courses';
import Footer from '../components/Footer';

const CoursesPage = () => {
  return (
    <Fragment>
        <NavigationBar/>
        <Courses/>
        <Footer/>
    </Fragment>
  )
}

export default CoursesPage