import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {db} from '../../firebase';
import { getDocs, collection, query, where} from 'firebase/firestore';


const AdminCoursesFinished = () => {
  const {t}=useTranslation();
  const [courses,setCourses]=useState([]);
  const getCourses = async () => {
    const q = query(collection(db, "courses"), where('status', '==', 'finished')); //OR
    const querySnapshot = await getDocs(q);
    setCourses([]);
    querySnapshot.forEach((doc) => {
      setCourses(last => [...last, doc]);
    });
  }
  useEffect(()=>{
    getCourses();
  },[db]);

  return (
    <div className='bg-app d-flex justify-content-center' style={{minHeight:'100vh',paddingTop:'10vh'}}>
        <div className='container-md'>
          <h2 className='my-3 text-center'>{t('general.historical')}</h2>
          <div className='table-responsive my-3'>
            <table className='table table-md table-hover align-middle'>
              <thead>
                <tr>
                  <th scope='col'>{t('general.language')}</th>
                  <th scope='col'>{t('general.level')}</th>
                  <th scope='col'>{t('general.teacher')}</th>
                  <th scope='col'>{t('general.type')}</th>
                  <th scope='col'>{t('general.duration')}</th>
                  <th scope='col'>{t('general.dateStart')}</th>
                  <th scope='col'>{t('general.dateEnd')}</th>
                  <th scope='col'>{t('general.price')}</th>
                  <th scope='col'>{t('general.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  courses.map(course=>
                    <tr key={course.id}>
                      <td>{course.data().language}</td>
                      <td>{course.data().level}</td>
                      <td>{course.data().teacher}</td>
                      <td>{course.data().type}</td>
                      <td>{course.data().duration}</td>
                      <td>{course.data().dateStart}</td>
                      <td>{course.data().dateEnd}</td>
                      <td>{course.data().price}</td>
                      <td>{course.data().actions}</td>
                    </tr>)
                }
              </tbody>
            </table>
          </div>
        </div>
    </div>
  )
}

export default AdminCoursesFinished