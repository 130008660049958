import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const RussianIntermediate = () => {
    const {t} = useTranslation();

    const questions= [{
            question: 'Один мой знакомый … музыкой с пяти лет.',
            answers: [
                'занимается',
                'занимаются',
                'занимаемся',
                'занимаюсь',
            ],
            key: 0,
            type: 1,
        },
        {
            question: `– Какое твоё хобби?
        – Я … шахматами.
        `,
            answers: [
                'увлекается',
                'увлекаюсь',
                'увлекаются',
                'увлекаться',
            ],
            key: 1,
            type: 1,
        },
        {
            question:
                'Они ещё плохо говорят по-английски, они только … говорить правильно.',
            answers: ['учатся', 'учимся', 'учится', 'учитесь'],
            key: 0,
            type: 1,
        },
        {
            question: 'Наша поездка в Японию запланирована … ',
            answers: [
                'на следующей неделе',
                'на следующую неделю',
                'следующая неделя',
            ],
            key: 0,
            type: 1,
        },
        {
            question: 'У меня нет … для юбилея.',
            answers: [
                'новое красивое платье',
                'нового красивого платья',
                'новому красивому платью',
            ],
            key: 1,
            type: 1,
        },
        {
            question: 'Мне нужно … в банк.',
            answers: ['позвоним', 'позвоню', 'позвонить', 'позвоните'],
            key: 2,
            type: 1,
        },
        {
            question:
                'Познакомьтесь, это мои друзья, … я вам рассказывала раньше.',
            answers: ['которые', 'о которых', 'к которым', 'у которых'],
            key: 1,
            type: 1,
        },
        {
            question:
                'Он давно хотел спросить, … у Сергея родственники в Латвии.',
            answers: ['когда бы', 'чтобы', 'есть ли', 'что'],
            key: 2,
            type: 1,
        },
        {
            question: 'На зимние каникулы мы все поедем в Сибирь … ',
            answers: [
                'у моей двоюродной сестры',
                'моя двоюродная сестра',
                'мою двоюродную сестру',
                'к моей двоюродной сестре',
            ],
            key: 3,
            type: 1,
        },
        {
            question: 'Раньше он жил у своего дяди … ',
            answers: [
                'на большом современном городе',
                'у большого современного города',
                'в большом современном городе',
                'с большим современным городом',
            ],
            key: 2,
            type: 1,
        },
        {
            question:
                'Моё письмо, … на прошлой неделе, он … только сегодня.',
            answers: [
                'отправила – получил',
                'отправленное – получил',
                'отправляемое – было получено',
                'сбыло отправлено – получит',
            ],
            key: 1,
            type: 1,
        },
        {
            question:
                'Договор о сотрудничестве, … сегодня, является выгодным для обеих сторон.',
            answers: [
                'подписанный',
                'был подписан',
                'подписав',
                'подписывая',
            ],
            key: 0,
            type: 1,
        },
        {
            question:
                '… над этим проектом в одной команде, они стали друзьями.',
            answers: ['Работали', 'Выработали', 'Работая', 'Работающие'],
            key: 2,
            type: 1,
        },
        {
            question:
                'Она очень скучала по дому и каждый раз, … глаза, она видела своё родное море.',
            answers: ['закрыв', 'закрывая', 'закрываемая', 'закрытые'],
            key: 1,
            type: 1,
        },
        {
            question: '… игры особенно полезны детям до семи лет.',
            answers: [
                'Развивающие',
                'Развитые',
                'Развиваемые',
                'Развивавшие',
            ],
            key: 0,
            type: 1,
        },
    ]
    const types = ['']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);

    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            window.location.href='/russian-advance/'+score;
          }
    }, [answered])
  return (
    <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
  )
}

export default RussianIntermediate
