import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const ItalianAdvance = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question:
                'Spero proprio che domani ............ una bella giornata, così potremo andare tutti al mare.',
            answers: ['è', 'sia', 'è stata', 'sia stata'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Peccato che non ............ comprare i biglietti per il teatro. Lo spettacolo è stato favoloso.',
            answers: [
                'abbiate potuto',
                'avete potuto',
                'siate potuti',
                'possiate',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'Tu mangi molta frutta e verdura fresca. ............ che ci tieni alla salute.',
            answers: ['si è visto', 'si vedeva', 'si vede', 'è visto'],
            key: 2,
            type: 0,
        },
        {
            question: '............ bene allunga la vita.',
            answers: [
                'avere mangiato',
                'mangiando',
                'avendo mangiato',
                'mangiare',
            ],
            key: 3,
            type: 0,
        },
        {
            question:
                'Ragazze, penso che domani ............ meglio a prendere il treno delle 7.50.',
            answers: ['facciate', 'facciano', 'abbiate fatto', 'faccia'],
            key: 0,
            type: 0,
        },
        {
            question:
                "Pur ............ mille volte di non ritornare a casa dopo la mezzanotte, voi siete ancora una volta arrivate all'una e mezza!",
            answers: ['avere detto', 'dire', 'avendo detto', 'dicendo'],
            key: 2,
            type: 0,
        },
        {
            question:
                "Non ............ l'ora di rivederti; è tanto che non facciamo una bella lunga chiacchierata insieme.",
            answers: ['vedo', 'guardo', 'ho visto', 'perdo'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Grazie mille per ............ il tuo abito da sera. Sei stata molto gentile.',
            answers: [
                'avere prestato',
                'avermi prestato',
                'avendo prestato',
                'prestarmi',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                "Signor Martini, ............ accompagnarmi a casa? Ho perso il mio portamonete e non ho i soldi per l'autobus.",
            answers: [
                'Le dispiacerebbe',
                'le dispiacerebbe',
                'la dispiacerebbe',
                'gli dispiacerebbe',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'Cari signori Maccani, ci farebbe molto piacere invitarvi a cena una sera durante il vostro soggiorno a Capri. Il nostro numero di telefono è 5614625. ............ saluti.',
            answers: ['distinti', 'vivi', 'meravigliosi', 'affezionati'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Vivo in un ............ appartamento nel centro della città saluti.',
            answers: ['bello', "bell'", 'bel', 'bella'],
            key: 1,
            type: 0,
        },
        {
            question:
                '............ fratellino si chiama Paolo e ha cinque anni.',
            answers: ['il mio', 'mio', 'la mia', 'mia'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Hai visto la partita Juventus-Milan? No, purtropp non ............',
            answers: [
                'lo ho visto',
                "l'ho visto",
                "l'ho vista",
                'lo ho vista',
            ],
            key: 2,
            type: 0,
        },
        {
            question:
                'Che cosa hai regalato a Luisa per il suo compleanno? ............ una borsa in pelle ed un paio di guanti.',
            answers: [
                'le ho regalato',
                'le ho regalate',
                'li ho regalati',
                "l'ho regalato",
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'Hai mandato a Nigel una cartolina da Roma? Sì ............ proprio ieri.',
            answers: [
                "gliel'ho mandata",
                'gliela ho mandata',
                've la ho mandata',
                'gliele ho mandate',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'Auguri per la tua laurea Ilaria! Quanto tempo ............ per laurearti? Quattro anni esatti.',
            answers: [
                'hai messo',
                'ci mettevi',
                'ci mettesti',
                'ci hai messo',
            ],
            key: 3,
            type: 0,
        },
        {
            question:
                'Quando sarò grande  ............ fare la manager in una grande società.',
            answers: ['ho voluto', 'vorrò', 'avrò voluto', 'sarò voluta'],
            key: 1,
            type: 0,
        },
        {
            question:
                '............ volentieri al cinema, ma Luisa è voluta andare per forza in discoteca.',
            answers: ['sarei andato', 'andrei', 'stavo andando', 'andavo'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Un tempo mio nonno ............ molto laborioso e ben conosciuto. Da quando è andato in pensione non fa quasi più niente.',
            answers: ['fu', 'è stato', 'era', 'era stato'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Due anni fa Luisa diede un ricevimento per festeggiare la sua laurea. Tre anni prima ............ in Francia con la sua amica del cuore, Fabrizia.',
            answers: ['era andata', 'andò', 'è stata', 'andava'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Quando ............ di banchettare, il Re Ludovico diede inizio alle danze.',
            answers: ['ha finito', 'finiva', 'aveva finito', 'ebbe finito'],
            key: 3,
            type: 0,
        },
        {
            question:
                "Dov'è Giorgio? Non l'ho visto per niente oggi pomeriggio. Penso che ............ a casa perchè aveva molti compiti da fare.",
            answers: ['è rimasto', 'rimaneva', 'rimase', 'sia rimasto'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Giulia dimmi: è vero che oggigiorno tutti gli italiani ............ passare le vacanze al mare?',
            answers: [
                'preferiscono',
                'preferiscano',
                'abbiano preferito',
                'preferivano',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                "Quest'anno temo che ............ rimanere in Inghilterra tutta l'estate per impegni di lavoro.",
            answers: ['devo', 'ho da', 'debba', 'abbia da'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Cercherò di organizzarmi il più presto possibile per la partenza, benchè non ............  ancora quando incominceranno di preciso le mie vacanze.',
            answers: ['so', 'sapevo', 'ho saputo', 'sappia'],
            key: 3,
            type: 0,
        },
    ]
    const types = ['']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);

    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            if (score <= 7) {
                alert('Tu nivel es B1');
                window.location.href = '/quiz-selection';
            } else if (score > 7 && score <= 15) {
                alert('Tu nivel es B2');
                window.location.href = '/quiz-selection';
            } else if (score > 15) {
                alert('Tu nivel es B2+');
                window.location.href = '/quiz-selection';
            }
        }


    }, [answered])
    return (
        <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
    )
}

export default ItalianAdvance