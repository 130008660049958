import React, { Fragment } from 'react';
import NavigationBar from '../components/NavigationBar';
import TeacherProfile from '../components/teacher/TeacherProfile';

const TeacherProfilePage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <TeacherProfile/>
    </Fragment>
  )
}

export default TeacherProfilePage