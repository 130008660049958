import React, { useEffect, useState } from 'react';
import {db} from '../firebase';
import {query,collection,getDocs,where} from 'firebase/firestore';
import CourseModal from './CourseModal';
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

const EMPTY_COURSE_DATA = {
  id:'',
  data:{
    dateEnd:'',
    dateStart:'',
    duration:'',
    schedule:[
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      },
      {
        class:false,
        starts:'',
        ends:''
      }
    ],
    level:'',
    price:'',
    teacher:'',
    type:''
  }}

const Courses = () => {
  const courseData = Cookies.get('courseData') ? JSON.parse(Cookies.get('courseData')) : undefined;
  const {t,i18n}=useTranslation();
  const [noCourses,setnoCourses]=useState('There´s no available courses at the moment ');
  const [data,setData]=useState(courseData? courseData : EMPTY_COURSE_DATA);
  const [modalShow, setModalShow] =useState(!!courseData);
  const [not,setNot]=useState(false);
  const [courses,setCourses]=useState([]);
  const [languages,setLanguages]=useState([]);
  const courseClassName='course';
  const backgrounds=['bg-green','bg-red','bg-blue','bg-purple','bg-yellow','bg-amethyst','bg-cyan'];
  var min=0;
  var max=backgrounds.length; 

  console.table(courseData)
  console.log(JSON.stringify(courseData))
  console.table(data)

  const getCourses=async()=>{
    const q = query(collection(db, "courses"), where('status', '==','ready')); //OR
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size<=0){
      setNot(false)
    }else{
      setNot(true)
      setCourses([]);
      querySnapshot.forEach((doc) => {
        setCourses(last => [...last, doc]);
      });
    }  
  }
  const getLanguages = async () => {
    const querySnapshot = await getDocs(collection(db, "languages"));
    setLanguages([]);
    querySnapshot.forEach((doc) => {
      setLanguages(last => [...last, doc]);
    });
  }

  useEffect(()=>{
    setnoCourses(i18n.language)
      if(i18n.language==='en'){
        setnoCourses('There´s no available courses at the moment ');
      }else{
        setnoCourses('No hay cursos por el momento.')
      }
  },[i18n.language])

  useEffect(()=>{
    getCourses();
    getLanguages();
  },[db]);

  const filterCourses=async(lng)=>{
    const q = query(collection(db, "courses"), where('status', '==','ready'),where('language','==',lng)); //OR
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size<=0){
      setCourses([]);
      setNot(false)
    }else{
      setNot(true)
      setCourses([]);
      querySnapshot.forEach((doc) => {
        setCourses(last => [...last, doc]);
      });
    }  
  }


  const onChangeSelect=()=>{
    let x=document.getElementById('filter').value;
    if(x==='all'){
      getCourses();
    }else{
      filterCourses(x)
    }
  }
  return (
    <div className='bg-app courses-container' >
      <div className='container-md'>
        <div className='d-flex justify-content-between'>
          <h2 className='text-left my-2'>{t('general.courses')}</h2>
          <span className='d-flex my-2'>
            <select className='form-control' id='filter' onChange={onChangeSelect} >
              <option value='all'>All languages</option>
              {
                languages.map((language)=>
                <option key={language.id} value={language.data().language} >
                  {language.data().language}
                </option>)
              }
            </select>
          </span>
        </div>
        <div className='divider'></div>
        <p className='lead' id='alert-courses' hidden={not}>{noCourses}</p>
        <div className='courses'>
          {
            courses.map(course=>
              <div 
              key={course.id} 
              className={courseClassName.concat(' ',backgrounds[Math.round(Math.random() * (+max - +min) + +min)])}
              onClick={()=>{
                setData({id:course.id,data:course.data()});
                setModalShow(true);
              }} >
                  <p>{course.data().language}-{course.data().level}</p>
                  <p>{t('general.teacher')}:{course.data().teacher.name}</p>
                  <p>{course.data().type}</p>
                  <p>{t('general.duration')}: {course.data().duration} hrs</p>
              </div>
                )
                //onClick={() => setModalShow(true)}
          }
        </div>
      </div>
      <CourseModal visible={modalShow} setVisible={setModalShow} data={data}/>
    </div>

  )
}

export default React.memo(Courses)