import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function MyVerticallyCenteredModal({ show, onHide, data }) {
    const { t, i18n } = useTranslation();
	const [week, setWeek] = useState([
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]);
	useEffect(() => {
		const days = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
		];
		const dias = [
			'Domingo',
			'Lunes',
			'Martes',
			'Miercoles',
			'Jueves',
			'Viernes',
			'Sábado',
		];
		if (i18n.language === 'es') {
			setWeek(dias);
		} else {
			setWeek(days);
		}
	}, [i18n.language]);
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('general.details')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <div className='course-modal-details'>
                    <h4>{data.data.language} - {data.data.level}</h4>
                    <table className='table table-sm table-hover align-middle'>
                        <tbody>
                            <tr>
                                <td>{t('general.teacher')}:</td>
                                <td><a href={'/teacher-profile/' + data.data.teacher.id}>{data.data.teacher.name}</a></td>
                            </tr>
                            <tr>
                                <td>{t('general.dateStart')}:</td>
                                <td>{data.data.dateStart}</td>
                            </tr>
                            <tr>
                                <td>{t('general.dateEnd')}:</td>
                                <td>{data.data.dateEnd}</td>
                            </tr>
                            <tr>
                                <td>{t('general.duration')}:</td>
                                <td>{data.data.duration} hrs</td>
                            </tr>
                            <tr>
                                <td>{t('general.price')}:</td>
                                <td>$ {data.data.price}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h5 className='mt-5'>{t('general.schedule')}</h5>
                    <table className='table table-sm table-hover align-middle'>
                        <thead>
                            <tr>
                                <th scope='col'></th>
                                <th scope='col'>{t('general.dateStart')}</th>
                                <th scope='col'>{t('general.dateEnd')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.data.schedule.map((day,index)=> <tr key={index}>
                                    <td>{week[index]}</td>
                                    <td>{data.data.schedule[index].starts}</td>
                                    <td>{data.data.schedule[index].ends}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>{t('general.close')}</Button>
                <Button onClick={() =>{
                    if(Cookies.get('name')!==undefined){
                        window.location.href='/payment/'+data.id;
                    }else{
                        if(i18n.language==='es'){
                            alert('Tienes que iniciar sesión');
                        }else{
                            alert('You need to login');
                        }
                        window.location.href='/login';
                        Cookies.set('pending', '/payment/'+data.id);
                        Cookies.set('courseData', JSON.stringify(data))
                        Cookies.set('goBackTo', window.location.href)
                    }
                }}>{t('general.rollme')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

const CourseModal = ({ visible, setVisible, data }) => {
    return (
        <div>
            <MyVerticallyCenteredModal show={visible}
                onHide={() => setVisible(false)} data={data} />
        </div>
    )
}

export default CourseModal