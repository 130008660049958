import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminCoursesFinished from '../../components/admin/AdminCoursesFinished';

const AdminCFinishedPage = () => {
  return (
    <Fragment>
        <AdminNavBar/>
        <AdminCoursesFinished/>
    </Fragment>
  )
}

export default AdminCFinishedPage