import { auth, db } from "../firebase";
import {
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Cookies from "js-cookie";
import { Timestamp } from "firebase/firestore"; 

const provider = new GoogleAuthProvider();

const setSession = (data) => {
    Cookies.set("id", data.id);
    Cookies.set("type", data.type);
    Cookies.set("name", data.name);
    Cookies.set("email", data.email);
    Cookies.set("photo", data.photo);
    window.location.href = "./"+data.type;
};

export const logIn = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password).then(
        async (credential) => {
            const user = credential.user;
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data={
                    id:user.uid,
                    type:docSnap.data().type,
                    name:docSnap.data().name,
                    email:docSnap.data().email,
                    photo:docSnap.data().photo
                }
                setSession(data);
                const goBackTo = Cookies.get('goBackTo');
                if(goBackTo){
                    window.location.href = goBackTo;
                }
            }
        }
    ).catch((error)=>{
        if(error.message==='Firebase: Error (auth/wrong-password).'){
            alert('Datos incorrectos')
        }
    });
};

export const googleSignIn = () => {
    signInWithPopup(auth, provider).then(async (result) => {
        const docRef = doc(db, "users", result.user.uid);
        const docSnap = await getDoc(docRef);
        let photoURL=result.user.photoURL;
        if (docSnap.exists()) {
            const data={
                id:result.user.uid,
                type:docSnap.data().type,
                name:docSnap.data().name,
                email:docSnap.data().email,
                photo:photoURL
            }
            setSession(data);
            const goBackTo = Cookies.get('goBackTo');
            if(goBackTo){
                window.location.href = goBackTo;
            }
        } else {
            alert("No estas registrado");
        }
    });
};

export const googleSignUp = (type)=>{
    signInWithPopup(auth, provider).then(async (result) => {
        const user=result.user;
        const docRef=doc(db,"users",result.user.uid);
        await setDoc(docRef,{
            name:user.displayName,
            email:user.email,
            type:type,
            photo:user.photoURL,
            created:Timestamp.fromDate(new Date())
        }).then(()=>{
            alert('Usuario registrado exitosamente');
            const goBackTo = Cookies.get('goBackTo');
            if(goBackTo){
                window.location.href = goBackTo;
            }
        })
    });
}
export const stdRegister = (data) => {
    createUserWithEmailAndPassword(auth, data.email, data.password).then(
        async (credential) => {
            const user = credential.user;
            await setDoc(doc(db, "users", user.uid), {
                name: data.name,
                email: data.email,
                type: "student",
                photo:"https://firebasestorage.googleapis.com/v0/b/languagecenter-5dbc8.appspot.com/o/285655_user_icon.png?alt=media&token=60caae6d-6fa8-4006-a325-9ba303430113",
                created:Timestamp.fromDate(new Date())
            }).then(() => {
                alert("Se registro exitosamente");
                const goBackTo = Cookies.get('goBackTo');
                if(goBackTo){
                    window.location.href = goBackTo;
                }
            });
        }
    );
};
export const tchrRegister = (data) => {
    createUserWithEmailAndPassword(auth, data.email, data.password).then(
        async (credential) => {
            const user = credential.user;
            await setDoc(doc(db, "users", user.uid), {
                name: data.name,
                email: data.email,
                type: "teacher",
                photo:"https://firebasestorage.googleapis.com/v0/b/languagecenter-5dbc8.appspot.com/o/285655_user_icon.png?alt=media&token=60caae6d-6fa8-4006-a325-9ba303430113",
                created:Timestamp.fromDate(new Date())
            }).then(() => {
                alert("Se registro exitosamente");
                document.getElementById("form-tchrReg").reset();
            });
        }
    );
};
export const logOut = () => {
    Cookies.remove("id");
    Cookies.remove("type");
    Cookies.remove("name");
    Cookies.remove("email");
    Cookies.remove("photo");
    window.location.href = '/';
};
export const recover = (email) => {
    sendPasswordResetEmail(email);
};

export const redirect=()=>{
    if(Cookies.get('type')===undefined){
        window.location.href='/';
    }
}
export const redirectIn=()=>{
    if(Cookies.get('type')!==undefined){
        window.location.href='/'+Cookies.get('type');
    }
}