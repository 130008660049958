import React, { Fragment } from 'react';
import NavigationBar from '../components/NavigationBar';
import ItalianLevels from '../components/quizzes/ItalianLevels';

const ItalianLevelsPage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <ItalianLevels/>
    </Fragment>
  )
}

export default ItalianLevelsPage