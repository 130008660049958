import React,{Fragment} from 'react'
import NavigationBar from '../components/NavigationBar'
import AboutUs from '../components/AboutUs'
import Footer from '../components/Footer'

const AboutUsPage = () => {
  return (
    <Fragment>
      <NavigationBar/>
      <AboutUs/>
      <Footer/>
    </Fragment>
  )
}

export default AboutUsPage