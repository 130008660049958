import React from 'react';
import { NavLink } from 'react-router-dom';

const RussianLevels = () => {
  return (
    <div className='bg-app std-height mt-5 pt-5'>
    <h2 className='text-center'>Levels</h2>
    <div className='d-flex flex-column'>
        <NavLink to='/russian-beginner' className='language-button'>Beginner</NavLink>
        <NavLink to='/russian-intermediate' className='language-button'>Intermediate</NavLink>
    </div>
</div>
  )
}

export default RussianLevels