import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';


const EnglishAdvance = () => {
    const {t} = useTranslation();

    const questions=[
        {
            question: '________ at school yesterday?',
            answers: ['Was you', 'Were you', 'Did you', 'Is you'],
            key: 1,
            type: 0,
        },
        {
            question: 'Is your family large? ________',
            answers: [
                'Yes, it is.',
                'Yes, they are.',
                'No, it not.',
                'No, they isn’t.',
            ],
            key: 0,
            type: 0,
        },
        {
            question: 'What ____ he want?',
            answers: ['does', 'do', 'have', 'No, they isn’t.'],
            key: 0,
            type: 0,
        },
        {
            question: '____ do you have dinner?',
            answers: ['When time', 'What time', 'What kind of', 'What for'],
            key: 1,
            type: 0,
        },
        {
            question: 'He ____ to go home.',
            answers: ['want', 'did', 'didn’t want', 'didn’t wanted'],
            key: 2,
            type: 0,
        },
        {
            question: 'Where ____  to school?',
            answers: ['did you go', 'went you', 'did you went', 'did go'],
            key: 3,
            type: 0,
        },
        {
            question: 'Latin ____ compulsory in Irish schools.',
            answers: ['used to be', 'would be', 'has', 'has been'],
            key: 0,
            type: 0,
        },
        {
            question:
                'The boy  ____ cake when his mother came into the room.',
            answers: ['was eat', 'eats', 'was eating', 'has eating'],
            key: 2,
            type: 0,
        },
        {
            question: 'There ____ milk for my breakfast.',
            answers: ['isn’t some', 'isn’t any', 'any', '-'],
            key: 1,
            type: 0,
        },
        {
            question:
                '____ people from Poland went to Scotland in the 20th century.',
            answers: ['Many of', 'Many', 'Some of', '-'],
            key: 1,
            type: 0,
        },
        {
            question: 'There are  __________ French speakers in Montreal.',
            answers: ['too much', 'a lot of', 'a little', 'not much'],
            key: 1,
            type: 0,
        },
        {
            question: 'She ____ with her friends on Facebook™ everyday',
            answers: [
                'is communicating',
                'communicates',
                'will communicating',
                '-',
            ],
            key: 1,
            type: 0,
        },
        {
            question: 'More and more people ____ divorced every year.',
            answers: ['are wanting', 'wanting', 'getting', 'are getting'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Many, but not all, people ____  get married in a church.',
            answers: [
                'want to',
                'are wanting to',
                'wanting to',
                'used to want',
            ],
            key: 0,
            type: 0,
        },
        {
            question: 'Would you like  ____ to the theatre tonight?',
            answers: ['go', 'to go', 'going', 'to going'],
            key: 1,
            type: 0,
        },
        {
            question: 'I ____ to Peru on holiday next month.',
            answers: ['am flying', 'flying', 'am go flying', 'will flying'],
            key: 0,
            type: 0,
        },
        {
            question: 'Oh! It ____. I’ll take an umbrella with me.',
            answers: ['raining', 'will raining', 'rains', '’s raining'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Do you have any plans for tonight? Yes, we ____ to the cinema.',
            answers: ['will go', 'going', 'go', 'are going'],
            key: 3,
            type: 0,
        },
        {
            question: 'I plan to ____ two weeks by the beach.',
            answers: ['bring', 'spend', 'spending', 'making'],
            key: 1,
            type: 0,
        },
        {
            question:
                'The fast food restaurant was _____ dirty. We didn’t eat there.',
            answers: ['extreme', 'extremely', 'bit', 'very much'],
            key: 1,
            type: 0,
        },
        {
            question: 'This restaurant is ____ the one over there.',
            answers: [
                'traditional',
                'traditionaler',
                'more traditional than',
                'traditionaler than',
            ],
            key: 2,
            type: 0,
        },
        {
            question: 'My coffee was ____ yours. I almost burned by mouth.',
            answers: [
                'hotter than',
                'more hot than',
                'hotter as',
                'as hot',
            ],
            key: 0,
            type: 0,
        },
        {
            question: 'The ____ coffee in the world comes from indonesia.',
            answers: [
                'expensive',
                'expensivest',
                'more expensive',
                'most expensive',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'I ____ sushi.',
            answers: [
                'eaten',
                'have eat',
                'have ever eaten',
                'have never eaten',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'She has ____ finished this week’s report.',
            answers: ['yet', 'already', 'ever', 'never'],
            key: 1,
            type: 0,
        },
        {
            question: 'I don’t think you ____ them.',
            answers: [
                'should to email',
                'should email',
                'should emailing',
                '-',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                'In the future there ____ cures to the world’s worst diseases.',
            answers: [
                'might be',
                'is going to being',
                'will being',
                'might have',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                'The space tourists ___ certainly need to be very fit.',
            answers: ['won’t', 'will', '-', 'going to'],
            key: 0,
            type: 0,
        },
        {
            question:
                'If my new company is successful, I  ____ employ people to help me.',
            answers: [
                'will',
                'be able to',
                'will be able to',
                'will able to',
            ],
            key: 2,
            type: 0,
        },
        {
            question: 'The first reality TV show ____ in Sweden in 1997.',
            answers: ['showed', 'shown', 'is shown', 'was shown'],
            key: 3,
            type: 0,
        },
        {
            question: 'The film Avatar was directed ____ James Cameron.',
            answers: ['by', 'from', 'for', 'with'],
            key: 0,
            type: 0,
        },
        {
            question: 'I’ve had my cat ____ 4 years.',
            answers: ['since', 'for', 'with', 'it'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Her horse is lovely. She _____ it since she was a teenager.',
            answers: ['had', 'has had', 'had', 'is had'],
            key: 1,
            type: 0,
        },
        {
            question: 'I’ve received 33 emails  ____ .',
            answers: [
                'on Friday',
                'yesterday',
                'two days ago',
                'this week',
            ],
            key: 3,
            type: 0,
        },
        {
            question: 'How often have you been to the doctor  ______',
            answers: [
                'one year ago?',
                'in the last twelve months?',
                'yesterday?',
                'last week?',
            ],
            key: 1,
            type: 0,
        },
        {
            question: 'I was saving up  ____ a new computer.',
            answers: ['for buying', 'to buy', 'to buying', '-'],
            key: 1,
            type: 0,
        },
        {
            question:
                'You ____ wear a suit to work, but you can if you want.',
            answers: ['must', 'mustn’t', 'could', 'don’t have to'],
            key: 0,
            type: 0,
        },
        {
            question:
                'I had to ____ a uniform to school when I was younger.',
            answers: ['have', 'wearing', 'wear', 'having'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Cecilia knows someone ____ went to the carnival in Rio de Janeiro.',
            answers: ['who', 'which', 'she', 'where'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Oxfam is a charity ____ tries to find lasting solutions to poverty.',
            answers: ['who', 'which', 'it', '-'],
            key: 1,
            type: 0,
        },
        {
            question: 'A lot of ____ came to Ireland in the 1990s.',
            answers: ['immigrants', 'emigrants', 'invaders', 'colonies'],
            key: 0,
            type: 1,
        },
        {
            question:
                'There was a nice meal and a band at the wedding ____',
            answers: ['ceremony', 'reception', 'speech', 'group'],
            key: 1,
            type: 1,
        },
        {
            question: 'I mostly ____ my friends via email.',
            answers: [
                'get on well with',
                'have in common',
                'keep in touch with',
                'see each other',
            ],
            key: 2,
            type: 1,
        },
        {
            question:
                'Bob has had a very interesting ___ . He has had jobs in many countries and industries.',
            answers: ['carrier', 'job', 'career', 'work'],
            key: 2,
            type: 1,
        },
        {
            question:
                'She’s very successful. Her ___ has risen a lot in the past few years.',
            answers: ['money', 'salary', 'job', 'earnings'],
            key: 1,
            type: 1,
        },
        {
            question: 'I am very ___ in old cars.',
            answers: ['keen', 'interesting', 'interested', 'fond'],
            key: 2,
            type: 1,
        },
        {
            question: 'He ___ his exam because he didn’t study.',
            answers: ['failed', 'passed', 'missed', 'fell'],
            key: 0,
            type: 1,
        },
        {
            question:
                'The house will look cleaner when you have finished the ____.',
            answers: ['home', 'housewife', 'housework', 'homework'],
            key: 2,
            type: 1,
        },
        {
            question:
                'Stress is not an illness, but it can ___ to many illnesses.',
            answers: ['get', 'celebrate', 'contribute', 'affect'],
            key: 2,
            type: 1,
        },
        {
            question: 'He ____ off his holiday until after the winter.',
            answers: ['took', 'put', 'called', 'logged'],
            key: 1,
            type: 1,
        },
    ]
    const types = ['Grammar', 'Vocabulary']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);
    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            if (score <= 35) {
                alert('Your level is Upper Intermediate');
                window.location.href = '/quiz-selection';
            } else if (score > 35) {
                alert('Your level es Advanced');
                window.location.href = '/quiz-selection';
            }
        }


    }, [answered])
    return (
        <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
    )
}

export default EnglishAdvance