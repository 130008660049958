import React, {  useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const ItalianBeginner = () => {
    const {t} = useTranslation();

    const questions = [
        {
            question: 'La stazione è ............',
            answers: ['aperto', 'moderno', 'chiusa', 'piccolo'],
            key: 2,
            type: 0,
        },
        {
            question:
                'La mia professoressa è nata ............ Bari in Puglia.',
            answers: ['a', 'di', 'in', 'su'],
            key: 0,
            type: 0,
        },
        {
            question: 'Ti piace ............ sport?',
            answers: ['il', 'la', "l'", 'lo'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Che cosa ............ di bello da vedere nella tua città?',
            answers: ["c'è", 'è', 'ci sono', 'e'],
            key: 0,
            type: 0,
        },
        {
            question: 'Come ............ tua madre?',
            answers: [
                'mi chiamo',
                'ci chiamiamo',
                'si chiama',
                'ti chiami',
            ],
            key: 2,
            type: 0,
        },
        {
            question:
                'Noi siamo italiani e voi di che nazionalità ............',
            answers: ['siete', 'sono', 'è', 'sei'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Io ho trentacique anni e tu quanti anni ............',
            answers: ['avete', 'hai', 'abbiamo', 'hanno'],
            key: 1,
            type: 0,
        },
        {
            question: 'Gianni e Rossella sono ............ zii di Tommaso.',
            answers: ['gli', 'le', 'la', 'i'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Questi pantaloni mi stanno molto bene, ............ prendo.',
            answers: ['lo', 'la', 'li', 'le'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Allora, ............ insieme a prendere qualcosa al bar? Sì, volentieri.',
            answers: ['vado', 'va', 'andiamo', 'andate'],
            key: 2,
            type: 0,
        },
        {
            question: 'Ciao, Giulia, come ............? Bene, grazie e tu?',
            answers: ['stai', 'sta', 'sto', 'stiamo'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Noi andiamo al cinema stasera e voi che cosa ............ di bello?',
            answers: ['fanno', 'faccio', 'fai', 'fate'],
            key: 3,
            type: 0,
        },
        {
            question:
                'Buongiorno, vorrei vedere ............ stivali sportivi, in pelle marrone.',
            answers: ['degli', 'delle', 'dei', 'dell'],
            key: 0,
            type: 0,
        },
        {
            question:
                "Buonasera signora, mi ............ dire per favore dov'è Piazza Navona?",
            answers: ['puoi', 'posso', 'possiamo', 'può'],
            key: 3,
            type: 0,
        },
        {
            question:
                "Mia madre è francese, e ............ padre di dov'è?",
            answers: ['tua', 'tuo', 'nostro', 'mio'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Sabato sono andato al cinema. E tu che cosa ............ la domenica?',
            answers: [
                'hai fatto',
                'ho fatto',
                'abbiamo fatto',
                'avete fatto',
            ],
            key: 0,
            type: 0,
        },
        {
            question:
                "L'estate scorsa Giulia e Roberta ............ in Italia dai loro genitori.",
            answers: [
                'sono andata',
                'sono andati',
                'siamo andati',
                'sono andate',
            ],
            key: 3,
            type: 0,
        },
        {
            question:
                'A Giovanna e Piero piace la pasta al pomodoro? Sì ............ piace moltissimo.',
            answers: ['si', 'le', 'gli', 'loro'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Scusi, Lei ............ a che ora arriva il treno da Firenze?',
            answers: ['conosce', 'sa', 'conosco', 'sai'],
            key: 1,
            type: 0,
        },
        {
            question:
                'Buongiorno signora, che cosa è successo? ............ la mia borsa con tutti i documenti. Mi può aiutare per favore?',
            answers: [
                'ho trovato',
                'ho comprato',
                'ho perso',
                'ho venduto',
            ],
            key: 2,
            type: 0,
        },
        {
            question: 'Allora Maria, veniamo da ............ per pranzo?',
            answers: ['me', 'noi', 'loro', 'te'],
            key: 3,
            type: 0,
        },
        {
            question:
                "C'è un bar qui vicino? Sì ............ uno proprio all'angolo.",
            answers: ["ce n'è", 'ce ne', "c'è", 'si è'],
            key: 0,
            type: 0,
        },
        {
            question:
                'Vuoi fermarti a cena da noi stasera? No, grazie ho ............ cenato.',
            answers: ['ancora', 'subito', 'già', 'di nuovo'],
            key: 2,
            type: 0,
        },
        {
            question:
                'Da quanto tempo vi conoscete tu e Mario? ............ da almeno cinque anni.',
            answers: [
                'si conoscono',
                'ci conosciamo',
                'mi conosce',
                'ti conosce',
            ],
            key: 1,
            type: 0,
        },
        {
            question:
                "Posso prendere un'altra pasta? Sì certo, ............ sono ancora tante!",
            answers: ['ce ne', 'ci', "ce n'è", 'ce'],
            key: 0,
            type: 0,
        },
    ]
    const types = ['']
    const [time, setTime] = useState(30);
    const [position, setPosition] = useState(0);
    const [progress, setProgress] = useState((time) * 100 / 30);
    const [start, setStarted] = useState(false);
    const [answered, setAnswered] = useState([]);
    const [aux, setAux] = useState(0);

    useEffect(() => {
        if (start === true) {
            if (aux === 0) {
                if (time > 0) {
                    setTimeout(() => { setTime(time - 1) }, 1000)
                } else if (time === 0) {
                    setPosition(position + 1);
                    setTime(30);
                }
            } else if (aux === 1) {
                setTime(30)
                setAux(0)
            }
        }
        setProgress((time) * 100 / 30)
    }, [time, start]);


    useEffect(() => {
        if (start === true && position < (questions.length - 1)) {
            setPosition(position + 1)
        }
        if (answered.length === questions.length) {
            let score = answered.filter(answer => answer === 1).length;
            if (score <= 15) {
                alert('Tu nivel es A1');
                window.location.href = '/quiz-selection';
            } else if (score > 15) {
                alert('Tu nivel es A2');
                window.location.href = '/quiz-selection';
            }
        }


    }, [answered])
    return (
        <div className='quiz-container' >
            <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
            <p className='question' hidden={!start}>{questions[position].question}</p>
            <div className='answers' hidden={!start}>
                {
                    questions[position].answers.map((answer, index) =>
                        <div key={index} className='answer' onClick={() => {
                            if (index === questions[position].key) {
                                setAnswered(answered.concat(1))
                            } else {
                                setAnswered(answered.concat(0))
                            }
                            setAux(1)
                        }}>
                            {answer}
                        </div>)
                }
            </div>
            <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
            <div className='quiz-progress'>{position + 1}/{questions.length}</div>
            <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
            <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
            <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
                setAnswered(answered.concat(0))
                setAux(1)
            }}>Next</button>
        </div>
    )
}

export default ItalianBeginner