import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminTeachers from '../../components/admin/AdminTeachers';

const AdminTeachersPage = () => {
  return (
    <Fragment>
            <AdminNavBar/>
            <AdminTeachers/>
    </Fragment>
  )
}

export default AdminTeachersPage