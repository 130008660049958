import React, { Fragment } from "react";
import NavigationBar from "../components/NavigationBar";
import Login from "../components/Login";
import Footer from "../components/Footer";

const LoginPage = () => {
  return (
    <Fragment>
      <NavigationBar />
      <Login />
      <Footer/>
    </Fragment>
  );
};

export default LoginPage;
