import React,{Fragment} from 'react';
import AdminNavBar from '../../components/admin/AdminNavBar';
import Admin from '../../components/admin/Admin';

const AdminPage = () => {
  return (
    <Fragment>
      <AdminNavBar/>
      <Admin/>
    </Fragment>
  )
}

export default AdminPage