import React, { useCallback } from 'react';
import classnames from 'classnames';
import { dailyMode, monthlyMode, yearlyMode } from './constants';
import { useTranslation } from 'react-i18next';
import './index.css';
const year = 'year';
const month = 'month';
const day = 'day';

export default function Mode({ active, onClick }) {
	const { t } = useTranslation();
	const returnModes = useCallback(() => {
		const modes = [
			{
				id: year,
				mode: yearlyMode,
				text: t('Year'),
			},
			{
				id: month,
				mode: monthlyMode,
				text: t('Month'),
			},
			{
				id: day,
				mode: dailyMode,
				text: t('Day'),
			},
		];
		if (Array.isArray(modes) && modes.length) {
			return modes.map((mode) => {
				const classNames = ['modeButton'];
				if (mode.mode === active) {
					classNames.push('modeButtonActive');
				}
				return (
					<button
						className={classnames(classNames)}
						key={mode.id}
						onClick={() => onClick(mode.mode)}
					>
						{mode.text}
					</button>
				);
			});
		}
	}, [active, onClick, t]);

	return <div className={'modeWrapper'}>{returnModes()}</div>;
}
