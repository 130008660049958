import React, {  useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';

const SpanishUpperIntermediate = () => {
  const {t} = useTranslation();

  const questions = [
    {
      question: 'Los niños todavía _________ dormidos.',
      answers: ['siguen', 'eran'],
      key: 0,
      type: 0,
    },
    {
      question: '¡Ojalá que no _________ mañana!',
      answers: ['llueva', 'llueve'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Te aconsejo que __________ más frutas y verduras, también que _________ mucha agua.',
      answers: ['coma, toma', 'comas, tomes'],
      key: 1,
      type: 0,
    },
    {
      question:
        'Ayer mi prima me dijo que me ___________ a su fiesta de aniversario.',
      answers: ['invitaba', 'invitaría'],
      key: 1,
      type: 0,
      lessGood: 0,
    },
    {
      question: 'No creo que _________ diciendo la verdad.',
      answers: ['estuvo', 'estaba'],
      key: 1,
      type: 0,
      lessGood: 0,
    },
    {
      question:
        'Cuando era pequeño soñaba en ______________ un astronauta.',
      answers: ['ser', 'convertirme en'],
      key: 1,
      type: 0,
      lessGood: 0,
    },
    {
      question:
        '_____________________ tres meses para el examen de admisión la universidad.',
      answers: ['quedan', 'Hay'],
      key: 0,
      type: 0,
    },
    {
      question:
        'Si me __________________ anoche para pedirme prestado el carro, hoy no _______________ tarde a la reunión.',
      answers: [
        'hubieras llamado, hubieras llegado',
        'hubieras llamado, llegarías',
      ],
      key: 0,
      type: 0,
    },
    {
      question:
        '______________ demasiados azúcares refinados y grasas provocan diabetes.',
      answers: ['consumiendo', 'consumir'],
      key: 1,
      type: 0,
    },
    {
      question:
        'Me caí al _____________ las escaleras de mi casa.',
      answers: ['no ver', 'estar bajando'],
      key: 1,
      type: 0,
      lessGood: 0,
    },
    {
      question:
        'Si ______________ practicando español, alcanzarás un gran nivel comunicativo.',
      answers: ['llevas', 'sigues'],
      key: 1,
      type: 0,
    },
    {
      question:
        '_________________ termines de hacer la tarea, por favor lava los platos sucios.',
      answers: ['cuando', 'al momento de'],
      key: 0,
      type: 0,
    },
    {
      question:
        '¿Podrías pasar por la panadería para comprar una tarta y un pastel?',
      answers: ['Sí podría, pero no tengo dinero.', 'Sí'],
      key: 0,
      type: 1,
      lessGood: 0,
    },
    {
      question: '¿Qué te pidió Marco que hicieras?',
      answers: [
        'Me pidió fuera al supermercado por queso y vino.',
        'Me pidió que fuera al supermercado por queso y vino.',
      ],
      key: 1,
      type: 1,
    },
    {
      question: '¿Te habría gustado ir a la Amazonia conmigo?',
      answers: [
        'Muy probablemente me habría encantado.',
        'Muy probablemente nos hubiéramos peleado.',
      ],
      key: 0,
      type: 1,
    },
    {
      question: '¿Por qué te volviste un carpintero?',
      answers: [
        'Me hice carpintero porque amo trabajar con la madera.',
        'Me volvieron carpintero porque amo trabajar con la madera.',
      ],
      key: 0,
      type: 1,
    },
    {
      question: '¿Les extrañaría si no voy a su fiesta?',
      answers: [
        'Yo creo que les pareciera muy extraño.',
        'Yo creo que les parecería muy extraño.',
      ],
      key: 1,
      type: 1,
    },
    {
      question: '¿Tu familia había estado en un lugar así antes?',
      answers: [
        'Siempre habíamos ido a Guaraná.',
        'Nunca habíamos ido a un lugar así.',
      ],
      key: 1,
      type: 1,
    },
    {
      question:
        '¡Qué sorpresa, cuánto tiempo sin vernos! ¿Qué has estado haciendo los últimos meses?',
      answers: [
        'Últimamente he estado realizando proyectos artísticos en un museo local.',
        'Últimamente he estado en un museo local.',
      ],
      key: 0,
      type: 1,
    },
    {
      question: '¿Cómo se enteró Pepe de la mala noticia?',
      answers: [
        'Se enteró por accidente mientras oía una conversación telefónica en la cocina.',
        'Se enteró por accidente cuando estuveís enfermo',
      ],
      key: 0,
      type: 1,
    },
    {
      question:
        'De acuerdo con la lectura, agua helada es sinónimo de',
      answers: ['agua tibia', 'agua fría', 'agua congelada'],
      key: 1,
      type: 2,
    },
    {
      question:
        'La gente que escogió escuchar música ¿cuántas veces más lograron mantener las manos debajo del agua?',
      answers: [
        'cinco veces más',
        'dos veces más',
        'el mismo número de veces',
      ],
      key: 0,
      type: 2,
    },
    {
      question: '¿Por qué es mejor la música que las drogas?',
      answers: [
        'porque estimula el alma',
        'porque no es adictiva',
        'porque combina distracción y emociones',
      ],
      key: 2,
      type: 2,
    },
    {
      question:
        'El doctor Raymond MacDonald qué explicación dio al hecho de escuchar música mientras hacemos ejercicio o estamos tristes.',
      answers: [
        'El doctor explicó que ayuda a soportar el dolor',
        'El doctor explicó que ayuda a apoyar el dolor',
        'El doctor explicó que ayuda a evitar el dolor',
      ],
      key: 0,
      type: 2,
    },
    {
      question: '¿Qué tipo de texto es el anterior?',
      answers: [
        'una noticia',
        'un reseña',
        'un ensayo',
        'un artículo',
      ],
      key: 3,
      type: 2,
    },
  ]
  const types = ['Completa la frase con la mejor opción',
    'Escoge la respuesta correcta',
    `Lee la siguiente noticia y responde las preguntas
Escuchar música alivia el estrés y el malestar físico
Además de proporcionar placer, escuchar la música preferida puede disminuir el dolor agudo o el estrés al ayudar al individuo a tomar un mayor dominio sobre sí, según un estudio divulgado en Gran Bretaña.\nPsicólogos de la Universidad de Caledonian de Glasgow, Escocia, apoyaron los resultados de su estudio en un experimento que involucró a un grupo de participantes, que tuvieron que sumergir las manos en agua helada. Cada uno de los voluntarios tuvo la oportunidad de elegir entre escuchar su música favorita, resolver operaciones de aritmética o mirar comedias en la televisión.\nLos participantes que escogieron escuchar la música de su preferencia lograron mantener las manos en el agua por más tiempo, indicaron los investigadores, en algunos casos hasta cinco veces más que el resto.\nLa música ayuda a las personas a relacionarse de forma emocional con sentimientos positivos, a diferencia de los estimulantes o las drogas, estima la doctora Laura Mitchell, que participó en el estudio. "Es la estrategia más efectiva para combinar distracción y sentimientos de control", puntualizó.\nPor su parte, el doctor Raymond MacDonald, profesor de Psicología de la Universidad de Glasgow y uno de los responsables de la investigación, indicó que las conclusiones revelan más del "poder de la música en nuestras vidas para soportar el dolor, sirve para confirmar la importancia de emitir música en los hospitales. Además, podría explicar porqué escuchamos música cuando hacemos deporte, en el gimnasio o cuando nos sentimos tristes".`
  ]
  const [time, setTime] = useState(45);
  const [position, setPosition] = useState(0);
  const [progress, setProgress] = useState((time) * 100 / 45);
  const [start, setStarted] = useState(false);
  const [answered, setAnswered] = useState([]);
  const [aux, setAux] = useState(0);
  useEffect(() => {
    if (start === true) {
      if (aux === 0) {
        if (time > 0) {
          setTimeout(() => { setTime(time - 1) }, 1000)
        } else if (time === 0) {
          setPosition(position + 1);
          setTime(45);
        }
      } else if (aux === 1) {
        setTime(45)
        setAux(0)
      }
    }
    setProgress((time) * 100 / 45)
  }, [time, start]);


  useEffect(() => {
    if (start === true && position < (questions.length - 1)) {
      setPosition(position + 1)
    }
    if (answered.length === questions.length) {
      let score = answered.filter(answer => answer === 1).length;
      if (score < 10) {
        alert('Tu nivel es B1+');
        window.location.href = '/quiz-selection';
      } else if (score >= 10 && score < 20) {
        alert('Tu nivel es B2-');
        window.location.href = '/quiz-selection';
      } else if (score >= 32 ) {
        alert('Tu nivel es B2+');
        window.location.href = '/quiz-selection';
      }
    }


  }, [answered])
  return (
    <div className='quiz-container-lg' >
      <p className='instruction' hidden={!start}>{types[questions[position].type]}</p>
      <p className='question' hidden={!start}>{questions[position].question}</p>
      <div className='answers-lg' hidden={!start}>
        {
          questions[position].answers.map((answer, index) =>
            <div key={index} className='answer' onClick={() => {
              if (index === questions[position].key) {
                setAnswered(answered.concat(1))
              } else {
                setAnswered(answered.concat(0))
              }
              setAux(1)
            }}>
              {answer}
            </div>)
        }
      </div>
      <p className='lead text-center mt-5 pt-5 display-4' hidden={start}>{t('quiz.instructions')}</p>
      <div className='quiz-progress'>{position + 1}/{questions.length}</div>
      <ProgressBar animated now={progress} label={`${time} segs`} className='time-bar' variant="danger" />
      <button className='btn btn-primary btn-start' hidden={start} onClick={() => setStarted(true)}>{t('quiz.start')}</button>
      <button className='btn btn-secondary btn-next' hidden={!start} onClick={() => {
        setAnswered(answered.concat(0))
        setAux(1)
      }}>Next</button>
    </div>
  )
}

export default SpanishUpperIntermediate