import React, { Fragment } from 'react';
import NavigationBar from '../components/NavigationBar';
import SpanishLevels from '../components/quizzes/SpanishLevels';


const SpanishLevelsPage = () => {
  return (
    <Fragment>
        <NavigationBar/>
        <SpanishLevels/>
    </Fragment>
  )
}

export default SpanishLevelsPage