import React,{Fragment} from 'react';
import TeacherNavBar from '../../components/teacher/TeacherNavBar';
import TeacherActive from '../../components/teacher/TeacherActive';

const TeacherActivePage = () => {
  return (
    <Fragment>
        <TeacherNavBar/>
        <TeacherActive/>
    </Fragment>
  )
}

export default TeacherActivePage