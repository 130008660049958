import React from 'react';
import { NavLink } from 'react-router-dom';

const ItalianLevels = () => {
    return (
        <div className='bg-app std-height mt-5 pt-5'>
            <h2 className='text-center'>Levels</h2>
            <div className='d-flex flex-column'>
                <NavLink to='/italian-beginner' className='language-button'>Beginner</NavLink>
                <NavLink to='/italian-intermediate' className='language-button'>Intermediate</NavLink>
                <NavLink to='/italian-advance' className='language-button'>Advanced</NavLink>
            </div>
        </div>
    )
}

export default ItalianLevels