import React from 'react';
import mapa from '../static/images/mapamundi.png';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Rotate from 'react-reveal/Rotate';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';


const Figures = () => {
    const duration=1200;
    const delay=500;
    const {t}=useTranslation();
    return (
        <div className='bg-app figures'>
            <Rotate top left duration={duration} delay={delay}>
                <div className='triangle'>
                    <NavLink to='/quiz-selection'>{t('figures.level')}</NavLink>
                </div>
            </Rotate>
            <Zoom top duration={duration} delay={delay}>
                <div className='square'>
                    <h1>{t('figures.center')}</h1>
                </div>
            </Zoom>
            <Flip left duration={2500} delay={delay}>
            <div className="earth ">
               <div style={{ backgroundImage: `url(${mapa})` }}></div>
            </div>
            </Flip>
        </div>            
    )
}

export default Figures